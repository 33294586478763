<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>{{ $t('actiondialog.embryo') }}</v-card-title>
      <v-card-text class="pt-5">
        <v-form ref="form">
          <v-row>
            <v-col
              v-if="action.type === 'Export'"
              cols="12"
              class="py-0"
            >
              <v-switch
                v-if="action.type === 'Export'"
                v-model="inHouse"
                inset
                :label="`${inHouse ? $t('actiondialog.house') : $t('actiondialog.owner')}`"
              />
            </v-col>
            <v-col
              v-if="action.type === 'Transfer'"
              cols="12"
            >
              <v-autocomplete
                v-model="selected"
                :label="$t('actiondialog.select')"
                :items="availableEmbryos"
                :item-text="embryoCode"
                item-value="_id"
                hide-details
                return-object
                multiple
                clearable
                outlined
              />
            </v-col>
            <v-col v-else>
              <search-customer
                :outlined="true"
                :clearable="true"
                :required="true"
                @emit-customer="customer = $event"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <simple-date-picker
                :date="date"
                :no-border="true"
                :expanded="true"
                @select-date="date = $event"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('close', !dialog)"
        >
          {{ $t('actiondialog.cancel') }}
        </v-btn>
        <v-btn
          color="success"
          text
          :loading="executing"
          :disabled="!selected.length || !date"
          @click="executeAction"
        >
          {{ action.type }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SimpleDatePicker from '@/components/DateSelector/SimpleDatePicker';
import SearchCustomer from '@/components/search/SearchCustomer';
import { icsiAPI } from '@/services';

export default {
  name: 'ActionDialog',
  components: { SimpleDatePicker, SearchCustomer },
  props: {
    action: { type: Object, required: true },
    selectedEmbryos: { type: Array, required: true },
    dialog: { type: Boolean },
  },
  data: () => ({
    available: [],
    executing: false,
    date: new Date(),
    customer: null,
    inHouse: true,
    selected: [],
    availableEmbryos: [],
  }),
  watch: {
    selectedEmbryos() {
      this.selected = this.selectedEmbryos;
    },
  },
  mounted() {
    this.getAvailableEmbryos();
  },
  methods: {
    embryoCode: (embryo) => embryo.code || '',
    async close() {
      await this.$refs.form.reset();
      this.inHouse = true;
      this.$emit('close', !this.dialog);
    },
    async executeAction() {
      this.executing = true;
      try {
        await Promise.all(this.selected.map((embryo) => this.action.exec(embryo._id, {
          inHouse: this.inHouse,
          customer: this.customer,
          date: this.date,
        })));
        await this.close();
        this.$emit('executed', this.action);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.executing = false;
      }
    },
    async getAvailableEmbryos() {
      this.loading = true;
      try {
        const { data: { embryos } } = await icsiAPI.getAvailableEmbryos();
        this.availableEmbryos = embryos;
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
