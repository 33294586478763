<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <labo-table
      :headers="headers"
      :title="title"
      :filters="filters"
    />
    <v-btn
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5"
      depressed
      @click="() => openLaboPage()"
    >
      {{ $t('labosindex.add') }}
      <v-icon right>
        mdi-plus
      </v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import laboTable from '@/components/labos/Table';
import i18n from '@/translations/i18n';
import { configAPI } from '@/services';

export default {
  components: { laboTable },
  data: () => ({
    title: 'Labo-onderzoeken',
    headers: [
      { text: i18n.t('labosindex.nlname'), value: 'name', selected: true },
      { text: i18n.t('labosindex.enname'), value: 'en', selected: false },
      { text: i18n.t('labosindex.frname'), value: 'fr', selected: false },
      { text: i18n.t('labosindex.account'), value: 'account', selected: true },
      { text: i18n.t('labosindex.price'), value: 'price', selected: true },
      { text: i18n.t('labosindex.fixed'), value: 'fixedPrice', selected: true },
      { text: i18n.t('labosindex.tax'), value: 'tax', align: 'left', selected: true },
      { text: i18n.t('labosindex.edit'), value: 'action', align: 'end', sortable: false, selected: true },
    ],
    taxes: [],
    prices: [
      { name: 'vast', value: true },
      { name: 'variabel', value: false },
    ],
  }),
  computed: {
    filters() {
      return {
        tax: {
          vTag: 'select',
          label: i18n.t('labosindex.filter'),
          items: this.taxes,
          options: { multiple: true },
        },
      };
    },
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    openLaboPage(id) { this.$router.push({ path: `/settings/labo/${id}` }); },
    async getConfig() {
      try {
        const { data: { tax } } = await configAPI.getProductConfig();
        this.taxes = tax;
      } catch (err) {
        await this.setError(err.message);
      }
    },
  },
};
</script>
