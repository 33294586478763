export const tenantInfo = {
  namespaced: true,
  state: () => ({
    tenants: [],
    activeTenant: undefined,
  }),
  mutations: {
    setTenants(state, tenants) {
      state.tenants = tenants;
    },
    setActiveTenant(state, activeTenant) {
      state.activeTenant = activeTenant;
    },
  },
  actions: {
    setTenants({ commit }, { tenants }) {
      commit('setTenants', tenants);
    },
    setActiveTenant({ commit }, { tenant }) {
      commit('setActiveTenant', tenant);
    },
  },
  getters: {
    getActiveTenantId(state) {
      return state.activeTenant?._id;
    },
    getActiveTenant(state) {
      return state.activeTenant;
    },
  },
};
