
import Vue, { PropType } from 'vue';
import { ownerName } from '@/Helpers';
import { teamleaderAPI } from '@/services';
import { IEmbryo } from '@/models/embryo';
import DatePicker from '@/components/DateSelector/DatePicker.vue';

interface IEmbryoBatch {
  endInvoice?: string;
  startInvoice?: string;
  dateImported?: string;
  batch_code: string;
  embryos: IEmbryo[];
}

export default Vue.extend({
  components: { DatePicker },
  props: {
    dialog: { type: Boolean },
    selected: {
      type: Array as PropType<Array<IEmbryo>>,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      client: null,
      valid: false,
      executing: false,
      embryoBatches: [] as IEmbryoBatch[],
    };
  },
  watch: {
    selected: {
      handler() {
        if (this.selected.length) {
          this.client = this.selected[0].owner;
          const embryosPerBatch = this.selected.reduce<{ [key: string]: IEmbryoBatch }>((previous, current) => {
            const result = { ...previous };
            if (!result[current.batch_code]) {
              result[current.batch_code] = {
                batch_code: current.batch_code,
                embryos: [],
              };
            }
            result[current.batch_code].embryos.push(current);
            return result;
          }, {});
          this.embryoBatches = Object.values(embryosPerBatch).map((embryoPerBatch) => {
            const startInvoice = new Date(
              Math.min(...embryoPerBatch.embryos.map((embryo) => new Date(embryo.date_imported).valueOf())),
            ).toISOString();
            const dateImported = new Date(
              Math.min(...embryoPerBatch.embryos.map((embryo) => new Date(embryo.date_imported).valueOf())),
            ).toISOString();
            return {
              batch_code: embryoPerBatch.batch_code,
              embryos: embryoPerBatch.embryos,
              dateImported,
              startInvoice,
            };
          });
        } else {
          this.embryoBatches = [];
        }
      },
      deep: true,
    },
  },
  methods: {
    ownerName,
    close() {
      (this.$refs.form as any).reset();
      this.$emit('close', !this.dialog);
    },
    setEndInvoiceDate(endDate: string, embryoBatch: IEmbryoBatch) {
      this.embryoBatches = this.embryoBatches.map((e) => ({
        ...e,
        endInvoice: e.batch_code === embryoBatch.batch_code ? endDate : e.endInvoice || endDate,
      }));
    },
    async invoiceICSI() {
      this.executing = true;
      try {
        await this.$store.dispatch('errorMessage/resetError');
        await teamleaderAPI.postICSIInvoice(this.embryoBatches);
        this.embryoBatches.forEach((embryoBatch) => {
          embryoBatch.embryos.forEach((embryo) => {
            const found = this.selected.find((s) => s._id === embryo._id);
            found.last_invoice_date = embryoBatch.endInvoice;
          });
        });
        this.$emit('executed', this.action);
        this.close();
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.executing = false;
      }
    },
  },
});
