<template>
  <products-form
    :product="product"
    :loading="loading"
    :disabled="disabled"
    route="/settings/products"
    @update-product="updateProduct"
  />
</template>

<script>
import productsAPI from '@/services/ProductsAPI';
import productsForm from '@/components/products/Form';

export default {
  components: { productsForm },
  props: ['id'],
  data() {
    return {
      disabled: false,
      product: {},
      loading: null,
    };
  },
  beforeMount() {
    if (this.id !== 'undefined') {
      this.getProduct(this.id);
    }
  },
  methods: {
    async getProduct(id) {
      try {
        this.loading = true;
        const { data } = await productsAPI.getProduct(id);
        this.product = data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    updateProduct(product) {
      this.product = product;
    },
  },
};
</script>
