<template>
  <v-card
    flat
    class="light-grey"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-card
        outlined
        class="ma-5"
      >
        <v-toolbar
          flat
          dense
        >
          <v-toolbar-title>{{ $t('semencreate.title') }}</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-row class="ma-5">
          <v-col
            v-if="id !== 'undefined'"
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="semenCollection.lotNumber"
              :label="$t('semencreate.lot')"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <search-horse
              :horse="semenCollection.stallion"
              :required="true"
              :disabled="id !== 'undefined'"
              horse-type="Hengst"
              :stud-only="true"
              @emit-horse="semenCollection.stallion = $event"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <search-customer
              :customer="semenCollection.owner"
              :disabled="id !== 'undefined'"
              :required="true"
              @emit-customer="updateCustomer"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="semenCollection.type"
              :disabled="!!semenCollection._id"
              :menu-props="{ offsetY: true }"
              :items="semenCollectionType"
              :label="$t('semencreate.type')"
              :rules="required"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <date-picker
              :disabled="!semenCollection.type"
              :date="semenCollection.production_date"
              :label="$t('semencreate.prod')"
              :close-on-input="true"
              :required="semenCollection.type === 'Productie'"
              :max="new Date()"
              @select-date="semenCollection.production_date = $event"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <date-picker
              :date="semenCollection.import_date"
              :label="$t('semencreate.import')"
              :close-on-input="true"
              :disabled="semenCollection.type !== 'Import'"
              :required="semenCollection.type === 'Import'"
              :max="new Date()"
              @select-date="semenCollection.import_date = $event"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <date-picker
              :date="semenCollection.last_invoice_date"
              :label="$t('semencreate.invoice')"
              :close-on-input="true"
              @select-date="semenCollection.last_invoice_date = $event"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-radio-group
              v-model="semenCollection.export_type"
            >
              <v-radio
                v-for="(type, idx) in exportTypes"
                :key="idx"
                class="mx-2"
                dense
                :label="type"
                :value="type"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        outlined
        class="ma-5"
      >
        <v-toolbar
          flat
          dense
        >
          <v-toolbar-title>{{ $t('semencreate.title2') }}</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-row class="ma-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-if="semenCollection.location"
              v-model="semenCollection.location.container"
              :menu-props="{ offsetY: true }"
              :label="$t('semencreate.container')"
              :items="nitrogenContainers"
              :rules="required"
              item-text="name"
              item-value="_id"
              return-object
              hide-details
            />
          </v-col>
          <v-col
            cols="6"
            md="4"
          >
            <v-select
              v-model="semenCollection.location.tube"
              :disabled="!semenCollection.location.container"
              :menu-props="{ offsetY: true }"
              :items="tubesAvailable(semenCollection.location.container)"
              :label="$t('semencreate.cooker')"
              hide-details
              :rules="required"
            />
          </v-col>
          <v-col
            cols="6"
            md="4"
          >
            <v-select
              v-model="semenCollection.location.position"
              :disabled="!semenCollection.location.container"
              :items="nitrogenContainerPosition"
              :menu-props="{ offsetY: true }"
              :label="$t('semencreate.pos')"
              hide-details
              :rules="required"
            />
          </v-col>
          <v-col
            cols="6"
            md="4"
          >
            <v-text-field
              v-model="semenCollection.initial_inventory"
              :disabled="!!semenCollection._id"
              :rules="required"
              type="number"
              :label="$t('semencreate.straw')"
              hide-details
            />
          </v-col>
          <v-col
            cols="6"
            md="4"
          >
            <v-select
              v-model="semenCollection.color"
              :menu-props="{ offsetY: true }"
              :items="semenCollectionColor"
              :label="$t('semencreate.color')"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row
          v-if="selectedContainer"
          class="ma-5"
        >
          <v-col>
            Resterende plaatsen: {{ selectedContainer.remaining_container_space }}
          </v-col>
        </v-row>
      </v-card>
      <v-card
        outlined
        class="ma-5"
      >
        <v-toolbar
          flat
          dense
        >
          <v-toolbar-title>{{ $t('semencreate.title3') }}</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-row class="ma-5">
          <v-col
            cols="6"
            md="4"
          >
            <v-text-field
              v-model="semenCollection.concentration"
              suffix="M/ml"
              type="number"
              :label="$t('semencreate.conc')"
              hide-details
              min="0"
            />
          </v-col>
          <v-col
            cols="6"
            md="4"
          >
            <v-text-field
              v-model="semenCollection.motility"
              suffix="%"
              type="number"
              :label="$t('semencreate.moti')"
              hide-details
              min="0"
              max="100"
            />
          </v-col>
          <v-col
            cols="6"
            md="4"
          >
            <v-text-field
              v-model="semenCollection.progress"
              suffix="%"
              type="number"
              :label="$t('semencreate.prog')"
              hide-details
              min="0"
              max="100"
            />
          </v-col>
          <v-col
            cols="6"
            md="4"
          >
            <v-text-field
              v-model="semenCollection.velocity"
              suffix="Mic/sec"
              type="number"
              :label="$t('semencreate.velo')"
              hide-details
              min="0"
            />
          </v-col>
          <v-col
            cols="6"
            md="4"
          >
            <v-text-field
              v-model="semenCollection.pail"
              suffix="Paill/dosis"
              type="number"
              :label="$t('semencreate.dose')"
              hide-details
              min="0"
            />
          </v-col>
          <v-col
            cols="6"
            md="4"
          >
            <v-text-field
              v-model="semenCollection.viability"
              suffix="%"
              type="number"
              :label="$t('semencreate.via')"
              hide-details
              min="0"
            />
          </v-col>
        </v-row>
      </v-card>
      <div class="mr-2">
        <v-row
          justify="end"
          dense
        >
          <v-btn
            depressed
            color="primary"
            class="mr-4 mb-5"
            @click="$router.back()"
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            {{ $t('semencreate.back') }}
          </v-btn>
          <v-btn
            v-if="id !== 'undefined'"
            :disabled="!valid"
            color="success"
            class="mr-4"
            depressed
            @click="updateSemenCollection"
          >
            <v-icon left>
              mdi-content-save-outline
            </v-icon>
            {{ $t('semencreate.edit') }}
          </v-btn>
          <v-btn
            v-if="id === 'undefined'"
            :disabled="!valid"
            color="success"
            class="mr-4"
            depressed
            @click="createSemenCollection"
          >
            <v-icon left>
              mdi-content-save-outline
            </v-icon>
            {{ $t('semencreate.save') }}
          </v-btn>
        </v-row>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import SearchHorse from '@/components/search/SearchHorse';
import SearchCustomer from '@/components/search/SearchCustomer';
import DatePicker from '@/components/DateSelector/DatePicker';
import { formatDate, formatDateRefMDY, ownerName, tubesAvailable } from '@/Helpers';
import { configAPI } from '@/services';
import HorseAPI from '@/services/HorseAPI';
import nitrogenContainerAPI from '@/services/NitrogenContainerAPI';
import semenAPI from '@/services/SemenAPI';
import { mapActions } from 'vuex';
import { exportTypes } from '@/consts/index.ts';
import i18n from '@/translations/i18n';

export default {
  components: { SearchHorse, SearchCustomer, DatePicker },
  props: {
    id: { type: String, default: '' },
  },
  data: () => ({
    valid: false,
    required: [(v) => !!v || i18n.t('semencreate.req')],
    semenCollection: {
      location: {
        container: null,
        tube: 0,
        position: 0,
      },
    },
    semenCollectionType: ['Productie', 'Import'],
    nitrogenContainerPosition: ['Boven', 'Onder'],
    semenCollectionColor: [],
    nitrogenContainers: [],
    exportTypes,
    selectedContainer: null,
  }),
  watch: {
    'semenCollection.location': {
      async handler() {
        if (this.semenCollection.location.container && this.semenCollection.location.tube && this.semenCollection.location.position) {
          const { data } = await nitrogenContainerAPI.getAvailableSemenContainerSpace(
            this.semenCollection.location.container._id,
            this.semenCollection.location.tube,
            this.semenCollection.location.position,
          );

          this.selectedContainer = data.container;
        }
      },
      deep: true,
    },
  },
  created() {
    Promise.all([this.getColors(), this.getNitrogenContainers()]);
  },
  async beforeMount() {
    if (this.id !== 'undefined') {
      await this.getSemenCollection(this.id);
    }
    if (this.$route.query.horse) {
      const { data } = await HorseAPI.getHorse(this.$route.query.horse);
      this.semenCollection.stallion = data;
    }
  },
  methods: {
    ownerName,
    formatDate,
    tubesAvailable,
    formatDateRefMDY,
    ...mapActions({
      resetError: 'errorMessage/resetError',
      setError: 'errorMessage/setError',
    }),
    updateCustomer(searchResult) {
      this.semenCollection.owner = searchResult ? searchResult._id : undefined;
    },
    async getSemenCollection(id) {
      try {
        await this.resetError();
        const { data } = await semenAPI.getSemenCollections(id);
        this.semenCollection = data;
      } catch (err) {
        await this.setError(err);
      }
    },
    async getColors() {
      try {
        const { data: { colors } } = await configAPI.getColors();
        this.semenCollectionColor = colors;
      } catch (err) {
        await this.setError(err);
      }
    },
    async getNitrogenContainers() {
      try {
        await this.resetError();
        const { data: { results } } = await nitrogenContainerAPI.getNitrogenContainers();
        this.nitrogenContainers = results;
      } catch (err) {
        await this.setError(err);
      }
    },
    async updateSemenCollection() {
      try {
        await this.resetError();
        await semenAPI.putSemenCollection(this.semenCollection);
        this.$router.back();
      } catch (err) {
        await this.setError(err);
      }
    },
    async createSemenCollection() {
      if (!this.semenCollection.lotNumber) this.semenCollection.lotNumber = formatDate(this.semenCollection.production_date);
      if (!this.semenCollection.owner) this.semenCollection.owner = this.horse.owner._id;
      if (!this.semenCollection.stallion) this.semenCollection.stallion = this.horse._id;
      try {
        await this.resetError();
        await semenAPI.postSemenCollection(this.semenCollection);
        this.$router.back();
      } catch (err) {
        await this.setError(err);
      }
    },
  },
};
</script>
