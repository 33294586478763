import { formatDate, horseName } from '../../Helpers';

export const strings = {
  ENG: {
    age: 'Age',
    condition: (stallion, caseDate) => `I confirm that she is in foal of a single foetus to the stallion
    ${horseName(stallion)} since ${formatDate(caseDate)}, that
    she is in good bodily condition to carry the prospective foal to
    full term.`,
    declaration: 'I declare that the mare is vaccinated against Rhino-pneumonia every 6 months and against influenza and tetanus every year.',
    donor_mare: 'Name of the donor mare',
    donor_stallion: 'Name of the donor stallion',
    undersigned: 'I, Undersigned',
    scans: (caseDate) => `Veterinary Surgeon, declare that an ultra-sound scan has been
    performed on ${formatDate(caseDate)} and a
    manual examination on ${formatDate(caseDate)}.`,
    select: 'To be selected',
    surrogate_mare: 'Name of the surrogate mare',
  },
  NL: {
    age: 'Leeftijd',
    undersigned: 'Ik, ondergetekend',
    condition: (stallion, caseDate) => ` Ik bevestig dat zij drachtig is van een enkele foetus van de hengst
    ${horseName(stallion)} sinds ${formatDate(caseDate)} en
    dat ze in goede lichamelijke conditie is om het toekomstige veulen te dragen.`,
    declaration: 'Ik verklaar dat de merrie zesmaandelijks tegen Rhino-pneumonie gevaccineerd is en jaarlijks tegen influenza en tetanus.',
    donor_mare: 'Naam van de donor merrie',
    donor_stallion: 'Naam van de donor hengst',
    scans: (caseDate) => `Dierenarts, verklaar dat een echografisch onderzoek is
    uitgevoerd op ${formatDate(caseDate)} en een
    rectaal onderzoek op ${formatDate(caseDate)}.`,
    select: 'Nog te selecteren',
    surrogate_mare: 'Naam van de draagmoeder',
  },
};
