<template>
  <v-container>
    <v-card
      outlined
      flat
      tile
      class="mb-2"
    >
      <v-card-title>
        <v-icon
          large
          left
        >
          mdi-alert-box
        </v-icon>
        <span class="title font-weight-light">{{ $t('auth.loggedOut') }}</span>
      </v-card-title>
      <v-card-text>
        {{ $t('auth.notIntentional') }}
        <v-btn
          depressed
          to="/"
          :link="true"
        >
          {{ $t('loginAgain') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'LoggedOut',
  methods: {},
};
</script>
