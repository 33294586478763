<template>
  <v-autocomplete
    v-model="selected"
    :label="label"
    :items="items"
    :menu-props="{closeOnContentClick:true}"
    prepend-inner-icon="mdi-magnify"
    :search-input.sync="search"
    :clearable="clearable"
    item-text="name"
    item-value="_id"
    :rules="rule"
    :autofocus="autofocus"
    return-object
    hide-no-data
    :hide-details="!showDetails"
    :solo-inverted="soloInverted"
    :outlined="outlined"
    :rounded="rounded"
    :dense="dense"
    :flat="flat"
    :disabled="disabled"
    @click:clear="clear"
  >
    <template #item="{ item }">
      <v-list-item
        v-if="item"
        @click="navigation ? navigateToHorse(item) : emitHorse(item)"
      >
        <v-list-item-action>
          <v-icon>{{ item.death ? 'mdi-christianity' : 'mdi-horseshoe' }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
            <em
              v-if="item.surrogate_uid"
              class="grey--text"
            > - {{ item.surrogate_uid }}</em>
          </v-list-item-title>
          <v-list-item-subtitle v-if="!showStock">
            {{ capitalize(item.type) }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="showStock && horseType === 'Merrie'">
            {{ item.availableEmbryos ? `${ item.availableEmbryos } embryos in stock` : 'Geen embryos in stock' }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.owner">
            {{ ownerName(item.owner) }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="showStock && horseType === 'Hengst'">
            {{ item.availableSemen ? `${ item.availableSemen } spermalot(en) in stock` : 'Geen sperma in stock' }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
import { SearchResultTypes } from '@/consts';
import { horseAPI, searchResultAPI } from '@/services';
import { ownerName, capitalize } from '@/Helpers';
import i18n from '@/translations/i18n';

export default {
  props: {
    horseType: { type: String, default: undefined },
    exclude: { type: String, default: undefined },
    horse: { type: [Object, String, undefined] },
    id: { type: String, default: undefined },
    surrogateOnly: { type: Boolean },
    soloInverted: { type: Boolean },
    showDetails: { type: Boolean },
    showStock: { type: Boolean },
    clearable: { type: Boolean },
    autofocus: { type: Boolean },
    required: { type: Boolean },
    outlined: { type: Boolean },
    disabled: { type: Boolean },
    studOnly: { type: Boolean },
    rounded: { type: Boolean },
    dense: { type: Boolean },
    flat: { type: Boolean },
    customer: { type: Object },
    navigation: { type: Boolean },
  },
  data() {
    return {
      loading: false,
      entries: [],
      search: null,
      selected: null,
    };
  },
  computed: {
    label() {
      if (this.horseType && this.horseType.toLowerCase() === 'hengst') {
        return this.studOnly ? i18n.t('common.search.stud') : i18n.t('common.search.stallion');
      }
      if (this.horseType && this.horseType.toLowerCase() === 'merrie') {
        return this.surrogateOnly ? i18n.t('common.search.surrogate') : i18n.t('common.search.mare');
      }
      return i18n.t('common.search.horse');
    },
    rule() {
      return [(value) => (this.required ? !!value || i18n.t('common.search.req') : true)];
    },
    items() {
      if (this.horse && this.horse._id && !this.entries.length) return [this.horse];
      return this.entries;
    },
  },
  watch: {
    search(val) {
      if (val && val !== this.selected) this.querySelections(val);
    },
    horse() {
      if (this.horse) {
        this.selected = this.horse;
      }
    },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        if (this.id && !this.entries.length) {
          this.getHorseById();
        }
        if (!this.id && !this.horse) {
          this.selected = {};
          this.entries = [];
        }
      },
    },
  },
  mounted() {
    if (this.horse) {
      this.selected = this.horse;
    }
  },
  methods: {
    ownerName,
    capitalize,
    uppercaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async querySelections(v) {
      this.loading = true;
      try {
        const { data } = await horseAPI.searchHorse(v, {
          type: this.horseType,
          stud_horse: this.studOnly ? this.studOnly : undefined,
          owner: this.customer ? this.customer._id : undefined,
          surrogate: this.surrogateOnly ? this.surrogateOnly : undefined,
          limit: 10,
        });
        this.entries = data.filter((horse) => horse._id !== this.exclude);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async getHorseById() {
      this.loading = true;
      try {
        const { data } = await horseAPI.getHorse(this.id);
        this.entries = [data];
        this.selected = data;
        this.$emit('fetched-item', data);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async navigateToHorse(horse) {
      try {
        searchResultAPI.postSearchResult({ searchItem: horse._id, searchType: SearchResultTypes.HORSE });
        this.$router.push(`/horse/${horse._id}?tab=general`);
      } catch (e) {
        await this.$store.dispatch('errorMessage/setError', { message: e.message, code: e.statusCode });
      }
    },
    clear() {
      this.selected = {};
      this.$emit('emit-horse', undefined);
    },
    emitHorse(item) {
      this.$emit('emit-horse', item);
      this.selected = item;
    },
  },
};
</script>
