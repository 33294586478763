<template>
  <div>
    <v-btn
      depressed
      color="primary"
      :disabled="!customer && !mare"
      @click="openDialog"
    >
      <v-icon left>
        mdi-history
      </v-icon>
      {{ $t('historydialog.ev') }}
    </v-btn>
    <v-dialog
      v-model="historyDialog"
      max-width="1400"
    >
      <v-card>
        <v-card-title>{{ customer ? ownerName(customer) : '' }} - {{ mare ? mare.name : '' }}</v-card-title>
        <v-data-table
          :headers="headers"
          :items="history"
          class="ma-5"
        >
          <template #no-data>
            {{ $t('historydialog.nodata') }}
          </template>
          <template #item="props">
            <tr>
              <td>{{ props.item.researchDate }}</td>
              <td>
                <tag
                  v-for="(follicle, i) in props.item.information.leftOvary.follicles"
                  :key="i"
                  :text="`${follicle.follicleTag ? follicle.follicleTag : ''} - ${follicle.size} mm`"
                />
              </td>
              <td>
                <tag
                  color="purple"
                  :text="oedeemLabels[props.item.information.uterus.rating]"
                />
                <tag
                  v-if="props.item.information.uterus.content && props.item.information.uterus.content.moist"
                  color="purple"
                  :text="`${props.item.information.uterus.content.moist ? props.item.information.uterus.content.moist : ''} vocht`"
                />
                <tag
                  v-if="props.item.information.uterus.content && props.item.information.uterus.content.type"
                  color="purple"
                  :text="props.item.information.uterus.content.type ? props.item.information.uterus.content.type : ''"
                />
              </td>
              <td class="text-right">
                <tag
                  v-for="(follicle, i) in props.item.information.rightOvary.follicles"
                  :key="i"
                  :text="`${follicle.follicleTag ? follicle.follicleTag : 'Geen tag'} - ${follicle.size} mm`"
                />
              </td>
              <td class="text-right">
                <v-icon
                  v-if="!!props.item.information.pregnant"
                  color="success"
                >
                  mdi-check
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer />
          <v-btn
            depressed
            color="primary"
            class="mr-3 mb-3"
            @click="historyDialog = false"
          >
            {{ $t('historydialog.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { formatDateFull, ownerName } from '@/Helpers';
import { CaseAPI } from '@/services';
import Tag from '@/components/common/Tag';
import i18n from '@/translations/i18n';

export default {
  components: { Tag },
  props: {
    mare: { type: Object, required: true },
    customer: { type: Object, required: true },
  },
  data: () => ({
    historyDialog: false,
    entries: [],
    oedeemLabels: ['Geen', 'Beginnend', 'Graad 1', 'Graad 2', 'Graad 3'],
    headers: [
      { text: '', value: 'researchDate', width: '150', sortable: false },
      { text: i18n.t('historydialog.left'), value: 'information.leftOvary', sortable: false },
      { text: i18n.t('historydialog.uterus'), value: 'information.uterus', sortable: false },
      { text: i18n.t('historydialog.right'), value: 'information.rightOvary', align: 'end', sortable: false },
      { text: i18n.t('historydialog.preg'), value: 'information.pregnant', width: '100', align: 'end', sortable: false },
    ],
  }),
  computed: {
    history() {
      return this.entries.map((entry) => ({
        _id: entry._id,
        information: entry.information,
        researchDate: formatDateFull(entry.researchDate),
        owner: this.customer,
      }));
    },
  },
  methods: {
    ownerName,
    formatDateFull,
    openDialog() {
      this.historyDialog = true;
      this.getHistory(this.$route.params.id);
    },
    async getHistory(id) {
      try {
        const { data: { researchSheets } } = await CaseAPI.get(id);
        this.entries = researchSheets;
      } catch (err) {
        console.log(err);
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>

<style scoped>
.customer {
  font-weight: normal !important;
}
</style>
