<template>
  <div>
    <v-card
      flat
      outlined
    >
      <v-toolbar
        flat
        dense
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="sheet && sheet.billable"
          color="primary"
          icon
          @click="addRow"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider v-if="currentSheetCysts && currentSheetCysts.length" />
      <v-data-table

        class="ma-5 small-first cystsTable"
        :headers="headers"
        :items="currentSheetCysts"
        hide-default-footer
        hide-default-header
      >
        <template #no-data>
          {{ $t('marecyst.nodata') }}
        </template>
        <template #[`item.size`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.size"
            large
          >
            {{ props.item.size }} mm
            <template #input>
              <v-text-field
                v-model="props.item.size"
                type="number"
                :label="$t('marecyst.editsize')"
                single-line
                autofocus
                counter
              />
            </template>
          </v-edit-dialog>
        </template>
        <template #[`item.position`]="props">
          <v-menu
            bottom
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-chip
                label
                small
                outlined
                v-bind="attrs"
                color="primary"
                v-on="on"
              >
                <span v-if="props.item.position">{{
                  props.item.position
                }}</span>
                <v-icon
                  v-if="!props.item.position"
                  small
                >
                  mdi-tag-outline
                </v-icon>
              </v-chip>
            </template>
            <v-list>
              <v-list-item
                v-for="(tag, i) in cystTypes"
                :key="i"
                @click="
                  () => {
                    props.item.position = tag;
                  }
                "
              >
                <v-list-item-title>{{ tag }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template #[`item.action`]="props">
          <v-tooltip
            v-if="sheet.billable"
            bottom
          >
            <template #activator="{ on }">
              <v-icon
                dark
                color="primary"
                @click="() => openDeleteDialog(props.item)"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('marecyst.delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <delete-dialog
      :delete-dialog="deleteDialog"
      item="Cyste"
      :delete-queue="deleteQueue"
      @delete="deleteItem"
      @close="closeDeleteDialog"
    />
  </div>
</template>

<script>
import DeleteDialog from '@/components/common/DeleteDialog';
import { formatDateFull } from '@/Helpers';
import i18n from '@/translations/i18n';

export default {
  components: { DeleteDialog },
  props: {
    title: { type: String, default: '' },
    cysts: { type: Object, default: () => {} },
    cystTypes: { type: Array, default: () => [] },
    sheet: { type: Object, default: () => {} },
  },
  data: () => ({
    headers: [
      { text: i18n.t('marecyst.cyst'), value: 'id' },
      { text: i18n.t('marecyst.size'), value: 'size', align: 'end' },
      { text: i18n.t('marecyst.location'), value: 'position', align: 'end' },
      { text: '', value: 'action', align: 'end' },
    ],
    deleteDialog: false,
    deleteQueue: {},
    currentSheetCysts: [],
  }),
  computed: {
    date() {
      return new Date(this.sheet.researchDate).getTime();
    },
  },
  watch: {
    cysts: {
      deep: true,
      immediate: true,
      handler() {
        const cysts = this.cysts ? this.cysts : {};
        const keys = Object.keys(cysts).sort();
        const currentIndex = keys.indexOf(this.date.toString());
        const firstDate = new Date().setTime(Number(keys[0]));
        if (currentIndex === -1 && new Date(this.date) > new Date(firstDate)) {
          const previous = cysts[keys[keys.length - 1]];
          this.currentSheetCysts = [...previous] || [];
        } else {
          this.currentSheetCysts = cysts[this.date] || [];
        }
      },
    },
  },
  methods: {
    formatDateFull,
    addRow() {
      const maxId = this.currentSheetCysts.reduce(
        (rv, curr) => Math.max(rv, Number(curr.id.split('#')[1])) + 1,
        1,
      );
      this.currentSheetCysts.push({ id: `#${maxId}`, size: 0, position: undefined });
      this.$emit('emit-cysts', {
        ...this.cysts,
        [this.date]: this.currentSheetCysts,
      });
    },
    deleteItem(cyst) {
      this.currentSheetCysts.splice(
        this.currentSheetCysts.findIndex((item) => item.id === cyst.id),
        1,
      );
      this.$emit('emit-cysts', {
        ...this.cysts,
        [this.date]: this.currentSheetCysts,
      });
      this.deleteQueue = {};
      this.deleteDialog = false;
    },
    openDeleteDialog(item) {
      this.deleteQueue = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog(state) {
      this.deleteQueue = {};
      this.deleteDialog = state;
    },
  },
};
</script>

<style>
.cystsTable tr td:nth-last-child(2) {
  max-width: 75px !important;
}
.cystsTable tr td:last-child {
  max-width: 30px !important;
}
</style>
