<template>
  <v-card flat>
    <v-toolbar flat>
      <v-spacer />
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            color="primary"
            class="ml-4 d-print-none"
            v-bind="attrs"
            :disabled="checkSelectedBatches"
            v-on="on"
          >
            <v-icon left>
              mdi-chevron-down
            </v-icon>
            {{ $t('batchtable.action') }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in actieMenu"
            :key="index"
            :disabled="item.singleSelect ? selectedBatches.length > 1 : false"
            @click="item.action(item.title)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetchBatches"
        @fetching="loading = $event"
        @fetchedRestProps="batches = $event.batches"
        @fetchedTotal="totalBatches = $event"
      />
      <columns
        :headers="headers"
        @emit-headers="filteredHeaders = $event"
      />
    </v-toolbar>
    <v-data-table
      v-model="selectedBatches"
      :headers="filteredHeaders"
      :items="batches"
      :loading="loading"
      :loading-text="$t('common.loading')"
      :server-items-length="totalBatches"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :options.sync="options"
      show-select
      :single-select="false"
    >
      <template #no-data>
        {{ $t('batchtable.nodata') }}
      </template>
      <template #item="props">
        <tr
          :style="{
            backgroundColor: (backgroundColor(props.item, selectedBatches, !props.item.active)),
            color: ((!props.item.active) ? '#999' : '')
          }"
        >
          <td>
            <v-checkbox
              class="ma-0 py-0"
              hide-details
              dense
              :input-value="props.isSelected"
              @change="props.select($event)"
            />
          </td>
          <td v-if="showColumn('lotNumber')">
            {{ props.item.lotNumber }}
          </td>
          <td v-if="showColumn('expirationDate')">
            {{ formatDate(props.item.expirationDate) }}
          </td>
          <td v-if="showColumn('deliveryDate')">
            {{ formatDate(props.item.deliveryDate) }}
          </td>
          <td v-if="showColumn('supplier')">
            {{ props.item.supplier }}
          </td>
          <td
            v-if="showColumn('buyInPrice')"
            class="text-right"
          >
            € {{ props.item.buyInPrice.toFixed(2) }}
          </td>
          <td
            v-if="showColumn('sellingPrice')"
            class="text-right"
          >
            € {{ props.item.sellingPrice.toFixed(2) }}
          </td>
          <td
            v-if="showColumn('sellingPricePerUnit')"
            class="text-right"
          >
            € {{
              props.item.sellingPricePerUnit.toFixed(2)
            }}
          </td>
          <td
            v-if="showColumn('initialAmount')"
            class="text-right"
          >
            {{ props.item.initialAmount }}
          </td>
          <td
            v-if="showColumn('remainingAmount')"
            class="text-right"
          >
            {{ props.item.remainingAmount }}
          </td>
          <td
            v-if="showColumn('updatedAt')"
            class="text-right"
          >
            {{ formatDate(props.item.updatedAt) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="d-flex justify-end mt-5">
      <v-btn
        color="success"
        class="ml-5 d-print-none"
        depressed
        @click="createDialog = true"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        {{ $t('batchtable.add') }}
      </v-btn>
    </div>
    <create-dialog
      :product-id="id"
      :dialog="createDialog"
      @close="createDialog = $event"
      @saved="saveBatch"
    />
    <mod-dialog
      :product-id="id"
      :dialog="editDialog"
      :current-batch="selectedRow"
      @close="editDialog = $event"
      @edited="editBatch"
    />
    <snackbar
      :snackbar="snackbar"
      :color="snackColor"
      :text="snackText"
      @timedout="snackbar = $event"
    />
  </v-card>
</template>

<script>
import { backgroundColor, formatDate, compareRouteAndPush } from '@/Helpers';
import { stockAPI } from '@/services';
import CreateDialog from '@/components/stock/dialogs/CreateStockBatchDialog';
import ModDialog from '@/components/stock/dialogs/StockBatchModDialog';
import Snackbar from '@/components/common/Snackbar';
import Filters from '@/components/filters/Filters';
import Columns from '@/components/filters/Columns';
import { mapActions } from 'vuex';
import i18n from '@/translations/i18n';

export default {
  components: {
    CreateDialog,
    ModDialog,
    Snackbar,
    Filters,
    Columns,
  },
  props: {
    id: { type: String, default: '' },
    headers: { type: Array, required: true },
    product: { type: Object, required: true },
    sortBy: { type: [String, Array], default: 'expirationDate' },
    sortDesc: { type: [String, Array, Boolean], default: false },
  },
  data: (data) => ({
    editDialog: false,
    createDialog: false,
    valid: false,
    loading: false,
    snackbar: false,
    snackText: '',
    snackColor: '',
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    selectedRow: {},
    totalBatches: 0,
    batches: [],
    selectedBatches: [],
    remaining: ['All', 'In stock', 'Out of stock'],
    filteredHeaders: [],
  }),
  computed: {
    actieMenu() {
      return [
        { title: i18n.t('batchtable.deactive'), action: this.deactivate },
        { title: i18n.t('batchtable.active'), action: this.activate },
        { title: i18n.t('batchtable.damage'), action: (type) => this.openEditDialog(type), singleSelect: true },
        { title: i18n.t('batchtable.sell'), action: (type) => this.openEditDialog(type), singleSelect: true },
        { title: i18n.t('batchtable.apply'), action: (type) => this.openEditDialog(type), singleSelect: true },
        { title: i18n.t('batchtable.check'), action: (type) => this.openEditDialog(type), singleSelect: true },
      ];
    },
    filters() {
      return {
        remaining: {
          vTag: 'select',
          label: i18n.t('batchtable.filter'),
          items: ['In stock', 'Out of stock'],
        },
      };
    },
    checkSelectedBatches() { return this.selectedBatches.length === 0; },
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush({
          ...this.$route.query,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
        }, this.$route, this.$router);
      },
    },
  },
  methods: {
    formatDate,
    backgroundColor,
    compareRouteAndPush,
    ...mapActions({
      resetError: 'errorMessage/resetError',
      setError: 'errorMessage/setError',
    }),
    showColumn(col) { return this.headers.find((header) => header.value === col).selected; },
    showSnackbar(color, text) {
      this.snackColor = color;
      this.snackText = text;
      this.snackbar = true;
    },
    openEditDialog(e) {
      this.editDialog = true;
      this.selectedRow = {
        id: this.selectedBatches[0].id,
        type: e,
      };
    },
    saveBatch() {
      this.createDialog = false;
      this.getStockProduct();
    },
    editBatch() {
      this.editDialog = false;
      this.getStockProduct();
      this.selectedBatches = [];
    },
    fetchBatches(URLparams) { return stockAPI.getStockProduct(this.id, URLparams); },
    async getStockProduct() {
      this.loading = true;
      try {
        const { data: { batches, total } } = await stockAPI.getStockProduct(this.id, {
          ...this.$route.query,
          tab: undefined,
        });
        this.batches = batches;
        this.totalBatches = total;
      } catch (err) {
        await this.setError(err.message);
      } finally {
        this.loading = false;
      }
    },
    async deactivate() {
      await this.resetError();
      try {
        this.loading = true;
        await Promise.all(this.selectedBatches.map(({ _id }) => stockAPI.deactivateBatch(_id)));
        await this.getStockProduct();
        this.selectedBatches = [];
        this.showSnackbar(i18n.t('batchtable.success'), i18n.t('batchtable.lot'));
      } catch (err) {
        await this.setError(err.message);
      } finally {
        this.loading = false;
      }
    },
    async activate() {
      await this.resetError();
      try {
        this.loading = true;
        await Promise.all(this.selectedBatches.map(({ _id }) => stockAPI.activateBatch(_id)));
        await this.getStockProduct();
        this.selectedBatches = [];
        this.showSnackbar(i18n.t('batchtable.success'), i18n.t('batchtable.lot'));
      } catch (err) {
        await this.setError(err.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.theme--dark.v-btn.v-btn--disabled .v-icon, .theme--dark.v-btn.v-btn--disabled .v-btn__loading {
  color: #999 !important;
}
</style>
