<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ title }} - {{ action.type.toLowerCase() }} ({{ selected.length }} {{ $t('actiondia.from') }} {{ maxItems }} {{ $t('actiondia.selected') }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col
              v-if="action.type === 'Change'"
              cols="6"
            >
              <v-switch
                v-model="inHouse"
                inset
                hide-details
                :label="`${inHouse ? $t('actiondia.house') : $t('actiondia.owner')}`"
              />
            </v-col>
            <v-col
              v-if="action.type !== 'Control'"
              cols="6"
            >
              <v-switch
                v-model="entireBatch"
                :label="$t('actiondia.entire')"
                hide-details
                inset
              />
            </v-col>
            <v-col
              v-if="action.type === 'Export' || action.type === 'Change'"
              cols="12"
            >
              <search-customer
                :outlined="true"
                :required="true"
                :customer="customer"
                :rules="required"
                @emit-customer="customer = $event"
              />
            </v-col>
            <v-col
              v-if="action.type === 'Control'"
              cols="12"
            >
              <v-select
                v-model="modAction"
                outlined
                :items="actions"
                item-text="text"
                item-value="value"
                :menu-props="{ offsetY: true }"
                hide-details
              />
            </v-col>
            <v-slide-y-transition>
              <v-col
                v-if="!entireBatch"
                cols="12"
              >
                <v-text-field
                  v-model="amount"
                  type="number"
                  :label="$t('actiondia.amount')"
                  :max="maxWithdrawal"
                  outlined
                  :rules="!entireBatch ? requiredNumber : []"
                  :disabled="entireBatch"
                />
              </v-col>
            </v-slide-y-transition>
            <simple-date-picker
              :date="date"
              :no-border="true"
              :expanded="true"
              @select-date="date = $event"
            />
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          {{ $t('actiondia.cancel') }}
        </v-btn>
        <v-btn
          color="success"
          text
          :loading="executing"
          :disabled="!selected.length || !valid"
          @click="executeAction"
        >
          {{ $t('actiondia.add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchCustomer from '@/components/search/SearchCustomer';
import SimpleDatePicker from '@/components/DateSelector/SimpleDatePicker';
import { required } from '@/Helpers';
import i18n from '@/translations/i18n';

export default {
  name: 'ActionDialog',
  components: { SearchCustomer, SimpleDatePicker },
  props: {
    title: { type: String, default: i18n.t('actiondia.action') },
    dialog: { type: Boolean },
    selected: { type: Array, required: true },
    action: { type: Object, required: true },
    maxItems: { type: Number, default: 0 },
  },
  data: () => ({
    valid: false,
    executing: false,
    amount: 0,
    inHouse: true,
    customer: null,
    date: new Date(),
    entireBatch: false,
    modAction: 'subtract',
    actions: [
      { text: i18n.t('actiondia.mult'), value: 'add' },
      { text: i18n.t('actiondia.sub'), value: 'subtract' },
    ],
  }),
  computed: {
    required,
    requiredNumber() {
      return [
        (v) => (v > 0 && v <= this.maxWithdrawal)
            || `Dit veld is verplicht, moet groter zijn dan 0 en kleiner dan ${this.maxWithdrawal}`,
      ];
    },
    maxWithdrawal() {
      if (this.selected.length && this.action.type !== 'Control') {
        const remaining = this.selected.map((batch) => batch.current_inventory);
        return Math.min(...remaining);
      }
      if (this.selected.length && this.action.type === 'Control') {
        const remaining = this.selected.map((batch) => batch.initial_inventory);
        return Math.min(...remaining);
      }
      return false;
    },
  },
  methods: {
    async close() {
      await this.$refs.form.reset();
      this.inHouse = true;
      this.modAction = 'subtract';
      this.$emit('close', !this.dialog);
    },
    async executeAction() {
      this.executing = true;
      try {
        await Promise.all(this.selected.map((semen) => this.action.exec(semen._id, {
          amount: this.entireBatch ? semen.current_inventory : this.amount,
          inHouse: this.inHouse,
          customer: this.customer || semen.owner,
          type: this.action.type,
          date: this.date,
          action: this.modAction,
        })));
        await this.close();
        this.$emit('executed', this.action);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.executing = false;
      }
    },
  },
};
</script>
