<template>
  <v-dialog
    v-model="showDeliveryProducts"
    persistent
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t('medinidialog.title') }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="importHeaders"
          :items="deliveries"
          :loading="loadingImport"
          loading-text="Importing deliveries..."
          sort-by="name"
          :sort-desc="false"
          :options.sync="options"
        >
          <template #no-data>
            {{ $t('medinidialog.nodata') }}
          </template>
          <template #item="props">
            <tr :style="{color: props.active ? '' : '#999'}">
              <td class="text-right">
                <v-text-field
                  v-model="props.item.product.CNK"
                  type="text"
                />
              </td>
              <td class="text-right">
                <v-text-field
                  v-model="props.item.product.name"
                  type="text"
                />
              </td>
              <td class="text-right">
                <v-select
                  v-model="props.item.product.tax"
                  :items="taxes"
                  :menu-props="{ offsetY: true }"
                />
              </td>
              <td>
                <v-text-field
                  v-model="props.item.lotNumber"
                  type="text"
                />
              </td>
              <td>
                <v-text-field
                  v-model="props.item.supplier"
                  type="text"
                />
              </td>
              <td class="text-right">
                <v-text-field
                  v-model="props.item.buyInPrice"
                  type="number"
                  step="0,01"
                  min="0"
                />
              </td>
              <td class="text-right">
                <v-text-field
                  v-model="props.item.sellingPrice"
                  type="number"
                  step="0,01"
                  min="0"
                />
              </td>
              <td class="text-right">
                <v-text-field
                  v-model="props.item.sellingPricePerUnit"
                  type="number"
                  step="0,01"
                  min="0"
                />
              </td>
              <td class="text-right">
                <v-text-field
                  v-model="props.item.initialAmount"
                  type="number"
                  step="1"
                  min="0"
                />
              </td>
              <td class="text-right">
                <v-text-field
                  v-model="props.item.product.dosage"
                  type="number"
                  step="0.1"
                  min="0"
                  :rules="required"
                />
              </td>
              <td class="text-right">
                <v-select
                  v-model="props.item.product.outgoingUnit"
                  :items="units"
                  clearable
                  :menu-props="{ offsetY: true }"
                />
              </td>
              <td>
                <v-menu
                  v-model="expirationDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="200"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :value="formatDate(props.item.expirationDate)"
                      persistent-hint
                      readonly
                      :rules="required"
                      v-on="on"
                    />
                  </template>
                  <vc-date-picker
                    v-model="props.item.expirationDate"
                    locale="nl"
                    :attributes="[{key: 'today',highlight: {color: 'gray',fillMode: 'light',}, dates: new Date(), }]"
                    :masks="{ title: 'MMM YYYY' }"
                  />
                </v-menu>
              </td>
              <td>
                <v-menu
                  ref="deliveryDateMenu"
                  v-model="deliveryDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="200"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :value="formatDate(props.item.deliveryDate)"
                      persistent-hint
                      readonly
                      :rules="required"
                      v-on="on"
                    />
                  </template>
                  <vc-date-picker
                    v-model="props.item.deliveryDate"
                    locale="nl"
                    :attributes="[{key: 'today',highlight: {color: 'gray',fillMode: 'light',}, dates: new Date(), }]"
                    :masks="{ title: 'MMM YYYY' }"
                  />
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          text
          @click="saveIncompleteProducts"
        >
          {{ $t('medinidialog.save') }}
        </v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="$emit('close', false)"
        >
          {{ $t('medinidialog.exit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate, required } from '@/Helpers';
import i18n from '@/translations/i18n';
import { configAPI, deliveryAPI } from '@/services';

export default {
  name: 'MediniDialog',
  props: {
    showDeliveryProducts: { type: Boolean },
  },
  data: () => ({
    expirationDateMenu: false,
    deliveryDateMenu: false,
    loadingImport: false,
    deliveries: [],
    originalDeliveries: [],
    taxes: [],
    units: [],
    options: {},
    importHeaders: [
      { text: i18n.t('medinidialog.cnk'), value: 'cnk', sortable: false, selected: true },
      { text: i18n.t('medinidialog.name'), value: 'product', sortable: false, selected: true },
      { text: i18n.t('medinidialog.tax'), value: 'tax', sortable: false, selected: true, width: 50 },
      { text: i18n.t('medinidialog.lot'), value: 'lotNumber', sortable: false, selected: true },
      { text: i18n.t('medinidialog.sup'), value: 'supplier', sortable: false, selected: true },
      { text: i18n.t('medinidialog.buy'), value: 'buyInPrice', sortable: false, selected: true },
      { text: i18n.t('medinidialog.sell'), value: 'sellingPrice', sortable: false, selected: true },
      { text: i18n.t('medinidialog.unit'), value: 'sellingPricePerUnit', sortable: false, selected: true },
      { text: i18n.t('medinidialog.amount'), value: 'initialAmount', sortable: false, selected: true, width: 75 },
      { text: i18n.t('medinidialog.dosage'), value: 'dosage', sortable: false, selected: true, width: 100 },
      { text: i18n.t('medinidialog.units'), value: 'units', sortable: false, selected: true, width: 175 },
      { text: i18n.t('medinidialog.expdate'), value: 'expirationDate', align: 'end', sortable: false, selected: true },
      { text: i18n.t('medinidialog.deldate'), value: 'deliveryDate', align: 'end', sortable: false, selected: true },
    ],
  }),
  computed: { required },
  watch: {
    showDeliveryProducts() {
      if (this.showDeliveryProducts) this.getDelivery();
    },
  },
  methods: {
    formatDate,
    async getDelivery() {
      this.loadingImport = true;
      try {
        const [{ data: { incompleteProducts } }, { data: { types, tax, units } }] = await
        Promise.all([deliveryAPI.getMediniDelivery(), configAPI.getProductConfig()]);
        this.types = types;
        this.taxes = tax;
        this.units = units;
        this.deliveries = incompleteProducts;
      } catch (err) {
        console.error(err);
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loadingImport = false;
      }
    },
    async saveIncompleteProducts() {
      try {
        this.loadingImport = true;
        const { data: { incompleteProducts } } = await deliveryAPI.saveIncompleteProducts(this.deliveries);
        this.deliveries = incompleteProducts;
        this.$emit('close', false);
        this.$emit('update-stock', true);
      } catch (err) {
        console.error(err);
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loadingImport = false;
      }
    },
  },
};
</script>
