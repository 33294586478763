<template>
  <v-layout
    column
    fill-height
  >
    <v-card
      class="mx-5 mt-5 no-print"
      outlined
    >
      <v-toolbar flat>
        <v-toolbar-title>{{ $t('semenstock.title') }}</v-toolbar-title>
      </v-toolbar>
      <v-row class="ma-5">
        <v-col>
          <search-customer
            :id="$route.query.owner"
            :outlined="true"
            :clearable="true"
            @fetched-item="owner = $event"
            @emit-customer="updateSearch('owner', $event)"
          />
        </v-col>
        <v-col>
          <search-horse
            :id="$route.query.stallion"
            :stud-only="true"
            :outlined="true"
            :clearable="true"
            @fetched-item="stallion = $event"
            @emit-horse="updateSearch('stallion', $event)"
          />
        </v-col>
      </v-row>
    </v-card>
    <semen-stock-table
      :owner="owner"
      :stallion="stallion"
      :filters="filters"
    />
    <v-btn
      v-if="!!filters.customer && !!filters.horse"
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5 no-print"
      depressed
      @click="print"
    >
      {{ $t('semenstock.print') }}
    </v-btn>
  </v-layout>
</template>

<script>
import { print, compareRouteAndPush } from '@/Helpers';
import SearchCustomer from '@/components/search/SearchCustomer';
import SearchHorse from '@/components/search/SearchHorse';
import SemenStockTable from '@/components/report/SemenStockTable';
import { configAPI } from '@/services';
import i18n from '@/translations/i18n';

export default {
  components: { SearchHorse, SearchCustomer, SemenStockTable },
  data: () => ({
    semenModTypes: [],
    owner: null,
    stallion: null,
  }),
  computed: {
    filters() {
      return {
        type: {
          vTag: 'select',
          label: i18n.t('semenstock.type'),
          items: ['Productie', 'Import'],
          options: { multiple: true },
        },
        modificationType: {
          vTag: 'select',
          label: i18n.t('semenstock.mod'),
          items: this.semenModTypes,
          options: { multiple: true },
        },
        dateRange: {
          vTag: 'dateRange',
          label: i18n.t('semenstock.semen'),
          from: 'from',
          to: 'to',
        },
        modsDataRange: {
          vTag: 'dateRange',
          label: i18n.t('semenstock.range'),
          from: 'modsFrom',
          to: 'modsTo',
        },
      };
    },
  },
  mounted() {
    this.getSemenMods();
  },
  methods: {
    print,
    compareRouteAndPush,
    openHorsePage(id) { this.$router.push(`/horse/${id}`); },
    updateSearch(key, value) {
      if (key === 'stallion') this.stallion = value;
      if (key === 'owner') this.owner = value;
      this.compareRouteAndPush({
        ...this.$route.query,
        [key]: value ? value._id : undefined,
      }, this.$route, this.$router);
    },
    async getSemenMods() {
      try {
        const { data: { semenModTypes } } = await configAPI.getSemenMods();
        this.semenModTypes = semenModTypes;
      } catch (err) {
        await this.setError(err.message);
      }
    },
  },
};
</script>
