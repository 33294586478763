export const reports = {
  insemination: {
    title: 'Inseminatie bewijs',
    client: 'Klant',
    mare: 'Merrie',
    name: 'Naam:',
    ueln: 'UELN:',
    chip: 'Microchip:',
    stud: 'Dekhengst',
    dateInsemination: 'Datum inseminatie:',
    dateControl: 'Datum controle:',
    datePregnancy: 'Datum geplande bevalling:',
    title2: 'Dagen toevoegen aan controle datum',
    amount: 'Aantal',
    pregnancyStatement: 'Gust verklaring',
    undersigned: 'Ondergetekende verklaart de bovengenoemde merrie te hebben gecontroleerd op drachtigheid door middel van echografie en werd drachtig/niet drachtig bevonden op',
    date: 'Datum',
    vet: 'Dierenarts',
    selectUndersigned: 'Selecteer ondergetekende',
    dateExecution: 'Datum uitvoering',
    addStatement: 'Voeg gust verklaring toe',
  },
  yearlyReports: {
    spermSentReceived: {
      stallion: 'Hengsten',
      fresh: 'VERS sperma',
      frozen: 'DIEPFRIES sperma',
      breed: 'Ras',
      amountOfStallions: 'Aantal hengsten',
      freshIn: 'Vers IN',
      freshOut: 'Vers OUT',
      frozenIn: 'Diepvries IN',
      frozenOut: 'Diepvries OUT',
    },
    spermGain: {
      noName: 'Geen naam',
      noBook: 'Geen stamboek',
      id: 'Identiteit van de hengsten',
      fresh: 'VERS SPERMA',
      frozen: 'FROZEN SPERMA',
      bookName: 'Stamboeknaam van de hengst (bij eerste inschrijving)',
      amountOfStallions: 'Aantal hengsten',
      freshWon: 'Aantal dosissen gewonnen',
      freshUsed: 'Aantal dosissen gebruikt op centrum',
      freshSent: 'Aantal dosissen verzonden',
      freshNotUsed: 'Aantal dosissen niet gebruikt',
      frozenWon: 'Aantal rietjes gewonnen',
      frozenUsed: 'Aantal rietjes gebruikt op centrum',
      frozenSent: 'Aantal rietjes verzonden',
    },
  },
};
