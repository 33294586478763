<template>
  <div>
    <v-card
      flat
      outlined
    >
      <v-toolbar
        flat
        dense
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-chip
          v-for="(tag, i) in ovaryTags"
          :key="i"
          label
          class="ml-3"
          :close="!disabled"
          color="secondary"
          text-color="primary"
          @click:close="closeChip(tag)"
        >
          {{ tag }}
        </v-chip>
        <v-menu
          v-if="!disabled && ovaryTagsList && ovaryTagsList.length"
          bottom
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-chip
              label
              v-bind="attrs"
              color="secondary"
              text-color="primary"
              class="ml-3 mr-3"
              v-on="on"
            >
              <v-icon small>
                mdi-tag
              </v-icon>
            </v-chip>
          </template>
          <v-list>
            <v-list-item
              v-for="(tag, i) in ovaryTagsList"
              :key="i"
              @click="addChip(tag)"
            >
              <v-list-item-title>{{ tag }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer />
        <v-btn
          v-if="!disabled"
          color="primary"
          icon
          @click="addRow"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider v-if="ovaryData && ovaryData.follicles && ovaryData.follicles.length" />
      <v-data-table
        v-if="ovaryData && ovaryData.follicles && ovaryData.follicles.length"
        class="ma-5 small-first ovaryTable"
        :headers="headers"
        :items="ovaryData.follicles"
        item-key="follicle"
        hide-default-footer
        hide-default-header
      >
        <template #no-data>
          {{ $t('mareovary.nodata') }}
        </template>
        <template #[`item.size`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.size"
            large
          >
            {{ props.item.size }} mm
            <template #input>
              <v-text-field
                v-model="props.item.size"
                type="number"
                :label="$t('mareovary.editsize')"
                single-line
                autofocus
                counter
              />
            </template>
          </v-edit-dialog>
        </template>
        <template #[`item.follicleTag`]="props">
          <single-tag-menu
            :small="true"
            :disabled="disabled"
            :current="props.item.follicleTag"
            :options="follicleTags"
            :outlined="true"
            color="primary"
            text-color="primary"
            @select="selected => props.item.follicleTag = selected"
          />
        </template>
        <template #[`item.action`]="props">
          <v-tooltip
            v-if="!disabled"
            bottom
          >
            <template #activator="{ on }">
              <v-icon
                color="primary"
                @click="() => openDeleteDialog(props.item)"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('mareovary.delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <delete-dialog
      :delete-dialog="deleteDialog"
      item="Follikel"
      :delete-queue="deleteQueue"
      @delete="deleteItem"
      @close="closeDeleteDialog"
    />
  </div>
</template>

<script>
import DeleteDialog from '@/components/common/DeleteDialog';
import SingleTagMenu from '@/components/common/SingleTagMenu';
import i18n from '@/translations/i18n';

export default {
  components: { DeleteDialog, SingleTagMenu },
  props: {
    title: { type: String, required: true },
    ovaryData: { type: Object, required: true },
    ovaryTags: { type: Array, default: () => ([]) },
    ovaryTagsList: { type: Array, default: () => (['Afwezig', 'Multifolliculair', 'Tumoraal']) },
    follicleTags: { type: Array, required: true },
    disabled: { type: Boolean },
  },
  data: () => ({
    headers: [
      { text: i18n.t('mareovary.fol'), value: 'follicle' },
      { text: i18n.t('mareovary.size'), value: 'size', align: 'end' },
      { text: i18n.t('mareovary.shape'), value: 'follicleTag', align: 'end' },
      { text: '', value: 'action', align: 'end' },
    ],
    deleteDialog: false,
    deleteQueue: {},
  }),
  methods: {
    closeChip(tag) {
      this.ovaryTags.splice(this.ovaryTags.findIndex((item) => item === tag), 1);
      this.ovaryTagsList.push(tag);
    },
    addChip(tag) {
      this.ovaryTags.push(tag);
      this.ovaryTagsList.splice(this.ovaryTagsList.findIndex((item) => item === tag), 1);
    },
    addRow() {
      this.ovaryData.follicles.push({
        follicle: `#${this.ovaryData.follicles.length + 1}`,
        follicleTag: undefined,
        size: 0,
      });
    },
    deleteItem(follicle) {
      this.ovaryData.follicles.splice(this.ovaryData.follicles.findIndex((item) => item.follicle === follicle.follicle), 1);
      this.deleteQueue = {};
      this.deleteDialog = false;
    },
    openDeleteDialog(item) {
      this.deleteQueue = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog(state) {
      this.deleteQueue = {};
      this.deleteDialog = state;
    },
  },
};
</script>

<style>
.ovaryTable tr td:nth-last-child(2) {
  max-width: 100px !important;
}

.ovaryTable tr td:last-child {
  max-width: 30px !important;
}
</style>
