<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="location.stable"
                  :items="stables"
                  :label="$t('locdialog.stable')"
                  hide-details
                  clearable
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="location.prefix"
                  :label="$t('locdialog.prefix')"
                  hide-details
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-model="location.name"
                  :rules="required"
                  :label="$t('locdialog.name')"
                  hide-details
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="location.places"
                  :rules="required"
                  type="number"
                  :label="$t('locdialog.amount')"
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          {{ $t('locdialog.cancel') }}
        </v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          text
          @click="save"
        >
          {{ $t('locdialog.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from '@/Helpers';
import i18n from '@/translations/i18n';
import locationAPI from '@/services/LocationAPI';

export default {
  name: 'CreateLocationDialog',
  props: {
    edit: { type: Boolean },
    dialog: { type: Boolean },
    selectedLocation: { type: Object, default: undefined },
    stables: { type: Array, default: () => [] },
  },
  data: () => ({
    valid: false,
    location: {},
    prefix: '',
  }),
  computed: {
    required,
    formTitle() { return !this.edit ? i18n.t('locdialog.new') : i18n.t('locdialog.edit'); },
  },
  watch: {
    edit() {
      if (!this.edit) {
        this.location = {};
        this.$refs.form.resetValidation();
      }
    },
    selectedLocation: {
      deep: true,
      handler() { this.location = this.selectedLocation || {}; },
    },
  },
  methods: {
    close() { this.$emit('close', false); },
    async save() {
      try {
        this.loading = true;
        await this.$store.dispatch('errorMessage/resetError');
        if (this.edit) {
          await locationAPI.putLocation(this.location);
        } else {
          await locationAPI.postLocation(this.location);
        }
        this.$emit('saved', this.location);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.close();
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
