export const englishToRefactor = {
  announcement: 'No new announcements',
  loading: 'Loading...',
  location: {
    table: 'No free spaces for this stable',
    header1: 'Name location',
    header2: 'Free spaces',
  },
  searchhorse: {
    title: 'Search horse',
    subtitle: 'Navigate directly to the desired horse here',
  },
  caseoverview: {
    backicon: 'back',
    delete: 'Delete case',
    bill: 'Intermediate billing',
    close: 'Close case',
    save: 'Save',
    protocols: 'protocols',
  },
  casedialog: {
    title: 'Create case',
    create: 'Cancel',
    cancel: 'Create',
    activity: 'Main activity',
  },

  expandtable: {
    tablecase: 'No cases added',
    loading: 'Retrieving research sheets...',
    header1: 'Research date',
    header2: 'Doctor',
  },

  pensioncost: {
    nodata: 'No boarding house found',
    header1: 'Type',
    header2: 'Location',
    header3: 'Arrival',
    header4: 'Departure',
  },
  addproduct: {
    add: 'Add products',
    id: 'Product with id:',
    gone: "doesn't exist anymore",
  },

  buttonaction: 'Actions',

  deletedialog: {
    title: 'delete',
    readyto: 'You are about to delete',
    todelete: '.',
    confirm: 'Are you sure you want to delete?',
    cancel: 'Cancel',
    delete: 'Delete',
  },

  horselocation: {
    title: 'Move horses',
    amount: 'Amount of free spots:',
    cancel: 'Cancel',
    move: 'Move',
    stable: 'Stable',
    location: 'Location',
    nodata: 'No location available',
  },

  relocatedialog: {
    title: 'Change location',
    cancel: 'Cancel',
    move: 'Move',
    tube: 'Tube',
    container: 'Nitrogen barrel *',
    position: 'Position *',
  },

  verificationdialog: {
    confirm1: 'Are you sure you want',
    confirm2: '',
    confirm3: '?',
    cancel: 'Cancel',
    default1: 'This item',
    default2: 'Confirm',
    default3: 'delete',
  },

  coveredmares: {
    horsesexpand: 'All covered mares',
    nodata: 'No data available',
    merrie: 'merrie',
    loading: 'Loading...',
    name: 'Name horse',
    type: 'Type horse',
    cover: 'Covered',
    ueln: 'UELN',
    chip: 'Microchip',
    owner: 'Owner',
    stallion: 'Stallion owner',
  },

  contacts: {
    add: 'Add contacts',
    cancel: 'Cancel',
    save: 'Save',
    nodata: "This client doesn't have contacts yet",
    first: 'Firstname',
    last: 'Lastname',
    phone: 'Phone',
    mail: 'Email',
    role: 'Save',
    edit: 'Save',
    new: 'New contact',
    edit2: 'Edit contact',
    loading: 'Loading...',
  },

  customertable: {
    add: 'Add client',
    nodata: 'No clients in the database',
    column: 'private',
  },

  form: {
    back: 'back',
    save: 'Save client',
    edit: 'Edit client',
    deleteclient: 'Delete client',
    deleteconfirm: 'Delete client?',
    confirm1: 'Are you sure you want to delete client',
    confirm2: 'This action cannot be reversed',
    cancel: 'Cancel',
    delete: 'Delete',
    part: 'Cancel',
    radio1: 'Private',
    radio2: 'Company',
    company: 'Company name *',
    last: 'Lastname',
    first: 'Firstname',
    vat: 'VAT-Number',
    vathint: 'example: BE0123456789',
    vatliab: 'VAT-Liability',
    language: 'Language *',
    mail: 'E-mail address',
    phone: 'Phonenumber - mobile',
    phonehint: 'example: +32486688502',
    phonefix: 'Phonenumber - fixed',
    phonefixhint: 'example: +3211781113',
    address: 'Address Line *',
    zip: 'ZIP',
    city: 'City',
    country: 'Country *',
    tlid: 'Teamleader ID',
    success: 'Client has been saved successfully',
  },
  searchhorseblock: {
    title: 'Most visited horses',
    subtitle: 'There are no results to show.',
  },

  expirelabblock: {
    title: 'Labs',
    subtitle: 'First 5 labs that expire',
    nodata: 'No labs that will expire soon',
    lab: 'Lab',
    horse: 'Horse',
    client: 'Client',
    expiration: 'Expiration date',
  },

  expireproductblock: {
    title: 'Products',
    subtitle: 'First 5 product batches that expire',
    nodata: 'No products that will expire soon',
    btn: 'More info',
    name: 'Product',
    lot: 'Batch',
    expiration: 'Expiration date',
  },

  highlighttitle: 'Highlighted',

  advconfigtitle: 'Advanced configuration',

  paramselecttitle: 'With these buttons you can add extra fields.',

  typeselect: 'With this selector you can generate a report within a case.',

  transactionconfig: 'With these selectors you can select the transaction type and action for this protocol regarding the action report.',
  transconfigselect: {
    type: 'Transaction type',
    action: 'Transaction action',
  },
  horseform: {
    title: 'General',
    title2: 'Location',
    name: 'Name horse*',
    sex: 'Sex*',
    birth: 'Birthdate',
    owner: 'Find owner',
    ueln: 'UELN',
    chip: 'Microchip',
    stallionowner: 'Search stallion owner',
    fee: 'Fee',
    death: 'Death date',
    surrogateuid: 'Surrogate brand number',
    stable: 'Stable',
    prefix: 'Prefix',
    location: 'Location',
    billable: 'Is this stay billable?',
    addspan: 'When creating a new horse with location, a running residence will automatically be created as well',
    title3: 'Features',
    breed: 'Breed',
    studbook: 'Pedigree',
    coat: 'Coat color',
    father: 'Father',
    mother: 'Mother',
    grandf: 'Grandfather',
    title4: 'Extra',
    stud: 'Stud',
    wffs: 'WFFS free',
    Draagmoeder: 'surrogate',
    chain: 'Hold for foodchain',
    dead: 'Died',
    back: 'Back',
    save: 'Save horse',
    download: 'Download researchsheet',
    edit: 'Update',
    delete: 'delete',
    condelete: 'Delete horse?',
    question1: 'Are you sure you want to delete the horse',
    question2: '? This action cannot be reversed',
    cancel: 'Cancel',
    delete2: 'Delete',
    success: 'Horse has been successfully saved',
  },

  labresult: {
    select: 'Select lab research',
    date: 'Research date',
    file: 'Select file',
    upload: 'Upload',
    nodata: 'No lab results',
    see: 'See file',
    delete: 'Delete file',
    filename: 'filename',
    research: 'Lab research',
    researchdate: 'Researchdate',
  },

  lodging: {
    in: 'Check-in',
    out: 'Check-out',
    nodata: 'No stays found for this stallion',
    days: 'days',
    dupe: 'Overlaps were found',
    edit: 'Edit stays',
    delete: 'Delete stay',
    add: 'Add new stay',
    arrival: 'arrival date',
    depart: 'departure date',
    stable: 'Stable',
    rule: 'Please choose a stable',
    prefix: 'Prefix',
    location: 'Location',
    rules: 'Please choose a location',
    full: '(Full)',
    free: 'Amount of free spots:',
    billable: 'Is this stay billable?',
    pension: 'Guesthouse type',
    datedupe: 'With the current arrival and departure dates, overlaps were found with other stays',
    cancel: 'Cancel',
    save: 'Save',
    horse: 'Horse',
    date1: 'Date arrival',
    date2: 'Date departure',
    stay: 'Duration of stay',
    status: 'Facturation status',
    edit2: 'Edit',
  },

  maretranssum: {
    win: 'Won',
    received: 'Received',
    sent: 'Sent',
    frozen: 'Frozen',
    icsi: 'Use for ICSI',
    egg: 'Egg cel',
    prod: 'Produced',
    plan: 'Planned',
    preg: 'Pregnant',
    days: '42 days',
    embryo1: 'Embryo by flushing',
    embryo2: 'Embryo by ICSI',
  },

  passport: {
    upload: 'Upload passport',
    see: 'See passport',
    delete: 'Delete passport',
  },

  staltranssum: {
    win: 'Won',
    use: 'Used',
    sent: 'Sent',
    import: 'Import',
    export: 'Export',
    amount: 'Doses semen (fresh)',
    seed: 'Semen straws (freezer)',
  },

  horsetable: {
    group: 'Group by location',
    nodata: 'No horses found',
    donor: 'Donor mare',
    implant: 'Date implant',
    move: 'Move',
    horse: 'Horses',
    dec: 'Deceased',
    mare: 'Mare',
    surr: 'Surrogate',
    mares: 'Mares',
    stallions: 'Stallions',
    studs: 'Studs',
    stallion: 'Stallion',
  },

  transtable: {
    nodata: 'No transactions found.',
    rap: 'Transaction rapport',
    filtertype: 'Filter on type',
    filteraction: 'Filter on action',
    type: 'Type',
    action: 'Action',
    in: 'In',
    out: 'Out',
    dest: 'Destination',
    date: 'Date',
  },

  invoicedialog: {
    embryo: 'Invoicing embryo storage -',
    batch: 'batch code',
    from: 'from *',
    untill: 'until *',
    cancel: 'Cancel',
    total: 'Checkout',
  },

  actiondialog: {
    embryo: 'Embryo action',
    house: 'In house storage',
    owner: 'Storage with owner',
    select: 'Select an embryo',
    cancel: 'Cancel',
  },

  embryotable: {
    embryos: 'Embryos',
    nodata: 'No Embryos found',
    cooker: '- Tube',
    embryo: 'embryo',
    export: 'Export',
    change: 'Change owner',
    stock: 'Invoice stock',
    edit: 'Change location',
    damage: 'Damaged',
  },

  icsiform: {
    title: 'Add ICSI',
    icsi: 'Code ICSI *',
    rules: 'This field is required and must be greater than 0',
    emamount: 'Amount of embryos',
    owner: 'Owner',
    importdate: 'Date import',
    title2: 'Location',
    color: 'Straw color',
    container: 'Nitrogen barrel *',
    cooker: 'Tube *',
    position: 'Position *',
    saved: 'ICSI is saved successfully',
    select: 'Select embryo',
    selectall: 'Select all',
    deselect: 'Deselect',
    back: 'back',
    save: 'ICSI Save',
    edit: 'edit',
    delete: 'delete',
    deletecon: 'Delete ICSI?',
    question1: 'Are you sure u want to delete the batch',
    question2: '? This action cannot be reversed',
    cancel: 'Cancel',
    delete2: 'Delete',
  },

  noicsidata: 'No ICSI batches found',

  laboform: {
    title: 'Lab research info',
    nlname: 'Research name - NL *',
    enname: 'Research name - EN',
    frname: 'Research name - FR',
    account: 'Research account',
    tax: 'Research tax *',
    price: 'Price *',
    pricefix: 'Fixed price *',
    config: 'Advanced configuration',
    back: 'back',
    save: 'Save lab research',
    edit: 'Edit lab research',
  },

  labotable: {
    nodata1: 'No',
    nodata2: 'found',
    edit: 'Edit research',
    delete: 'Delete research',
  },

  nodata: 'No data available',

  livestockreg: {
    title: 'Register present animals',
    daily: 'Daily overview',
    yearly: 'Annual overview',
    year: 'Year',
    avg: 'Average occupation per stable',
  },

  yearlylivestock: {
    nodata: 'No horses on stable',
    month: 'Month',
    amount: 'Amount of horses per stable',
  },

  locationselector: {
    container1: 'Location - Nitrogen barrel',
    container2: 'Location - Nitrogen barrel - Tube',
    container3: 'Location - Nitrogen barrel - Tube - Position',
  },

  notifeditor: {
    span: 'Here you have the option to add notifications.',
    notif: 'Notification',
    notiftext: 'Message',
    days: 'Amount of days',
    tag: 'Tags',
  },

  notifmenu: {
    title: 'No new notifications',
    subtitle: 'Woohoo!',
    less: 'Load less',
    more: 'Load more',
  },

  pensionform: {
    title: 'Guesthouse info',
    nlname: 'Guesthouse name - NL *',
    enname: 'Guesthouse name - EN *',
    frname: 'Guesthouse name - FR *',
    account: 'Guesthouse account',
    tax: 'Guesthouse tax *',
    price: 'Guesthouse price per day*',
    available: 'Available for',
    back: 'back',
    save: 'Save guesthouse',
    edit: 'Edit guesthouse',
  },

  pensiontable: {
    nodata1: 'No',
    nodata2: 'found',
    edit: 'Edit guesthouse',
    delete: 'Delete guesthouse',
  },

  productform: {
    title: 'Product info',
    name: 'Product name *',
    type: 'Product type *',
    tax: 'Product tax *',
    cnk: 'CNK *',
    dosage: 'amount/batch *',
    unit: 'unit',
    wait: 'Waitingtime',
    supplement: 'Supplement administration',
    price: 'Sale price per unit',
    back: 'back',
    save: 'Save product',
    edit: 'Edit product',
  },

  producttable: {
    nodata: 'None',
    edit: 'Edit product',
    delete: 'Delete product',
  },

  protocolform: {
    title: 'Protocol info',
    nlname: 'Protocol - NL *',
    enname: 'Protocol - EN *',
    frname: 'Protocol - FR *',
    account: 'Protocol account',
    tax: 'Protocol tax *',
    price: 'Price *',
    invoice: 'Invoice description',
    pricefix: 'Fixed price *',
    config: 'Advanced configuration',
    custom: 'Custom invoice description',
    back: 'back',
    save: 'Save Protocol',
    edit: 'Edit protocol',
  },

  protocoltable: {
    nodata1: 'No',
    nodata2: 'found',
    edit: 'Edit protocol',
    delete: 'Delete Protocol',
  },

  sementable: {
    showmods: 'Show stock changes',
    mods: 'stock changes',
    stallion: 'Stallion',
    info: 'batchnumber / info',
    start: 'Start stock',
    edits: 'Changes',
    left: 'Remaining',
    date: 'Date',
    rap: 'Stock rapport',
    nodata: 'No stock found',
    select: 'Select a customer & a horse for the report',
  },

  sementotal: {
    span1: 'Initial',
    span2: 'Decrease',
    span3: 'Stock',
  },

  marecyst: {
    nodata: 'No cysts found',
    editsize: 'Edit thickness',
    delete: 'Delete cyst',
    cyst: 'Cyst',
    size: 'Thickness',
    location: 'Location',
  },

  historydialog: {
    ev: 'evolution',
    nodata: 'No evolution available/found',
    close: 'close',
    left: 'Left ovary',
    uterus: 'Uterus',
    right: 'Right ovary',
    preg: 'Pregnant?',
  },

  mareovary: {
    nodata: 'No follicles found',
    editsize: 'Edit thickness',
    delete: 'Delete follicle',
    fol: 'Follicle',
    size: 'Thickness',
    shape: 'Shape',
  },

  uterusform: {
    title: 'Uterus',
    span: 'Oedema',
    moist: 'Moist',
    type: 'Type of moist',
    preg: 'Pregnant',
    amount: 'Amount',
  },

  miniparam: {
    nodata: 'No parameters in this protocol',
    horse: 'Horse:',
    custom: 'Recipient:',
    amount: 'Specific amount:',
    horse2: 'Horse',
    amount2: 'Amount',
    location: 'Location',
  },

  miniproduct: {
    nodata: 'No products in this protocol',
    edit: 'Edit',
    name: 'Product name',
    amount: 'Amount',
  },

  researchform: {
    doc: 'Veterinarian',
    date: 'Research date',
    back: 'back',
    delete: 'delete',
    save: 'Save research sheet',
  },

  searchlabotable: {
    nodata1: 'No',
    nodata2: 'added',
    delete: 'Delete research',
    select: 'Select lab research',
    none: 'Nothing selected',
    cancel: 'Cancel',
    add: 'Add',
    prodname: 'Productname',
    amount: 'Amount',
    labname: 'Lab name',
    date: 'Date',
  },

  paramform: {
    add: 'Add semen batch',
    select: 'Select embryo',
    nodata: "No embryo's in stock from this donor",
  },

  prodtable: {
    title: 'Medication',
    nodata1: 'No',
    nodata2: 'added',
    edit: 'Add',
    delete: 'Delete product',
    title2: 'Add medication',
    none: 'Nothing selected',
    sell: 'Sell',
    cancel: 'Cancel',
    add: 'Add',
    amount: 'Amount',
    name: 'Product name',
    dose: 'Dosage',
    edit2: 'Edit',
  },

  certtable: {
    nodata1: 'No',
    nodata2: 'added',
    span: 'Certificates',
    name: 'Protocol name',
    date: 'Date',
  },

  prototable: {
    nodata1: 'No',
    nodata2: 'added',
    delete: 'Delete protocol',
    none: 'Nothing selected',
    desc: 'Invoice description:',
    desc2: 'Invoice description',
    place: 'Fill in your invoice description here',
    cancel: 'Cancel',
    add: 'Add',
    amount: 'Amount',
    name: 'Protocol name',
    date: 'Date',
  },

  selectsemen: {
    select: 'Select semen',
    nodata: 'No semen in stock from this donor',
    rest: 'Remaining:',
    straw: 'Straw',
    dose: 'Xpaill/dose:',
    amount: 'Amount of straws *',
    max: 'maximum',
    oblig: 'This field is required, has to be smaller then 0',
  },

  researchtable: {
    select: 'Select all billable',
    deselect: 'Deselect all billable',
    nodata1: 'No',
    nodata2: 'found',
    date: 'Research date',
    doctor: 'Doctor',
    billable: 'Billable',
  },

  vat: 'VAT:',

  basicresearch: {
    chip: 'chip:',
    nr: 'brandnr:',
  },

  horsesearch: {
    stud: 'Search stud',
    stallion: 'Search stallion',
    sur: 'Search surrogate',
    mare: 'Search mare',
    search: 'Search horse',
    req: 'this field is required',
  },

  labosearch: {
    search: 'Search labo research',
    req: 'this field is required',
  },

  prodsearch: {
    all: 'All Products',
    stock: 'in stock',
    search: 'Search product',
  },

  protosearch: {
    select: 'select',
    protselect: 'Select protocol',
    search: 'Search protocol',
    req: 'this field is required',
  },

  semeninvoice: {
    title: 'Billing stock -',
    cancel: 'Cancel',
    bill: 'Billing',
  },

  semenbatch: {
    lot: 'batch',
    start: 'from *',
    end: 'until *',
  },

  actiondia: {
    house: 'In house stock',
    owner: 'Stock with the owner',
    entire: 'Full batches',
    amount: 'Amount',
    cancel: 'Cancel',
    add: 'Add',
    action: 'Sperma action',
    mult: 'Add up',
    sub: 'Subtract',
    from: 'from the',
    selected: 'selected)',
  },

  dialogcreate: {
    info: 'Info from the new batch',
    editinfo: 'Edit batch',
    type: 'Semen batch type *',
    prod: 'Semen production date',
    import: 'Semen import date',
    container: 'Nitrogen barrel *',
    cooker: 'Tube *',
    position: 'Position *',
    straw: 'Amount of straws *',
    color: 'Straw color',
    concen: 'Concentration',
    moti: 'Motility',
    prog: 'Progressivity',
    velo: 'Velocity',
    dose: 'Xpail/dose',
    via: 'Viability',
    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
  },

  dialogdelete: {
    span: 'Delete batch',
    fill: 'Fill in the name of the stallion (',
    confirm: ') to confirm you want to delete this batch of semen.',
    cancel: 'Cancel',
    delete: 'Delete',
  },

  dialogedit: {
    span: 'Add stock changes to this batch',
    type: 'Type',
    export: 'Export to',
    amount: 'Amount of straws',
    cancel: 'Cancel',
    save: 'Save',
    return: 'After checking 1 straw too short then fill in -1 / After checking 1 straw too much then fill in 1',
  },

  exporttype: {
    select: 'Select the export type (',
    from: 'from the',
    selected: 'selected)',
    cancel: 'Cancel',
    save: 'Save',
  },
  moddialog: {
    span: 'Details from the stock changes on this batch',
    nodata: 'No changes on this batch have been found yet',
    close: 'Close',
    type: 'Type',
    client: 'Client',
    straw: 'Amount of straws',
    horse: 'Horse',
    date: 'Date',
  },

  tablesemen: {
    nodata: 'No semen batches found',
    cooker: '- Tube',
    edit: 'Edit batch',
    see: 'See stock changes',
    params: 'Quality parameters',
    lot: 'Batch',
    stallion: 'Stallion',
    owner: 'Owner',
    ini: 'Initial',
    left: 'Remaining',
    prod: 'Production date',
    import: 'Import date',
    exportdate: 'Export date',
    invoice: 'Last invoice',
    location: 'Location & color',
    exptype: 'Export type',
    type: 'Type',
    export: 'Export',
    change: 'Change owner',
    stock: 'Bill stock',
    editlocation: 'Change location',
    damage: 'Damaged',
    destroy: 'Destroy',
    control: 'Control',
    filtertype: 'Filter on type',
    filterexp: 'Filter on export type',
    hide: 'Hide empty batches',
  },

  valuedialog: {
    span: 'Qualitative parameters',
    params: 'No qualitative parameters found for this batch',
    conc: 'Concentration',
    moti: 'Motility',
    prog: 'Progressivity',
    velo: 'Velocity',
    dose: 'Xpail/dose',
    via: 'Viability',
    close: 'Close',
  },

  selectsigned: 'Select undersigned',

  createbatch: {
    title: 'Add new batch',
    nr: 'Batch number *',
    exp: 'Expiration date *',
    sup: 'Supplier *',
    deliv: 'Delivery date *',
    buy: 'Purchase price',
    sell: 'Sale price/unit *',
    ini: 'Initial Amount *',
    cancel: 'Cancel',
    save: 'Save',
  },

  stockbatch: {
    title: 'Add product - type:',
    type: 'type *',
    amount: 'Amount *',
    cancel: 'Cancel',
    save: 'Save',
  },

  batchmods: {
    nodata1: 'No stock modifications for product',
    nodata2: 'found.',
    type: 'Type',
    prod: 'Product',
    lot: 'Batch number',
    client: 'Client',
    horse: 'Horse',
    amount: 'Amount',
    date: 'Date',
    filter: 'Filter on modification',
  },

  batchtable: {
    action: 'Actions',
    nodata: 'No batches found',
    add: 'Add new batch',
    deactive: 'Deactivate',
    active: 'Activate',
    damage: 'Damaged',
    sell: 'Sell',
    apply: 'Dosage',
    check: 'Controle',
    filter: 'Filter on remaining',
    success: 'success',
    lot: 'batch successfully activated',
  },

  nomods: 'No stock modifications for product found.',

  stocktable: {
    buy: 'Total remaining purchase price:',
    sell: 'Total remaining sale price:',
    nodata: 'No products found',
  },

  transactionper: {
    title: 'Transactions by stallion owner',
    year: 'Year',
    search: 'Search stallion owner',
    nodata: 'No transactions available',
    stallion: 'Stallion owner',
    horse: 'Name horse',
    type: 'Type',
    action: 'Action',
    date: 'Date',
    destination: 'Destination',
  },

  uploadform: {
    file: 'Choose file',
    upload: 'Upload',
    uploadfile: 'upload .CSV files',
  },

  embryogain: {
    nodata: 'No data available',
    mare: 'Mares',
    win: 'Winning of ovums',
    use: 'Use of ovums',
    breed: 'Breed',
    amount: 'Amount',
    winamount: 'Amount of won ovums',
    receiveamount: 'Amount of received ovums',
    frozenamount: 'Amount of frozen ovums',
    sentamount: 'Amount of sent ovums',
    icsi: 'Amount of ovums used for ICSI on the centrum itself',
  },

  embryoicsi: {
    nodata: 'No data available',
    mare: 'Mares',
    amountem: "Amount of embryo's",
    preg: 'Pregnancy',
    breed: 'Breed',
    amount: 'Amount',
    prod: 'Produced',
    frozen: 'Frozen',
    sent: 'Sent',
    planted: 'Implanted',
    min: 'Amount of surrogates that are at least 42 days pregnant',
  },

  embryorinsed: {
    nodata: 'No data available',
    mare: 'Mares',
    amountem: "Amount of embryo's",
    preg: 'Pregnancy',
    breed: 'Breed',
    amount: 'Amount',
    win: 'Winnings',
    frozen: 'Frozen',
    sent: 'Verzonden',
    planted: 'Implanted',
    min: 'Amount of surrogates that are at least 42 days pregnant',
  },

  spermgain: {
    nodata: 'No data available',
    noname: 'No name',
    nobook: 'No studbook',
    id: 'Identity of the stallions',
    info: 'Data semen gain d =  dose fresh / r = straw freezer',
    name: 'studbook name',
    enroll: 'At least one studbook of registration',
    amount: 'Amount of won doses fresh / straws in centrum',
  },

  generalinfo: {
    company: '1. Information about your company.',
    name: 'Name',
    nr: 'Company number',
    address: 'street english number',
    zip: 'zip english city',
    contact: '2. Contact information',
    fullname: 'first- and lastname company manager',
    tel: 'phone number',
    phone: 'cellphone number',
    fax: 'fax number',
    mail: 'e-mailadres',
    site: 'website',
    fill: '3. Fill in the declaration below.',
    accept: 'I declare that I have entered the above information truthfully.',
    date: 'Date',
    sign: 'Signature',
    fullname2: 'first- and lastname',
    selection: 'Selection fields',
    select: 'select undersigned',
    print: 'print',
  },

  yearlyreport: {
    title: 'Annual report',
    gen: 'General info',
    tables: 'Tables',
    select: 'Select a year',
    table: 'Table',
    print: 'print all tabkes',
  },

  medinidialog: {
    title: 'Imported batches',
    nodata: 'No deliveries found',
    save: 'Save',
    exit: 'Close',
    cnk: 'CNK',
    name: 'Name',
    tax: 'Tax',
    lot: 'Batch number',
    sup: 'Supplier',
    buy: 'Purchase price',
    sell: 'Sale price',
    unit: 'Unit price',
    amount: 'Amount',
    dosage: 'Amount/batch',
    units: 'Unit',
    expdate: 'Expiration date',
    deldate: 'Delivery date',
  },

  login: {
    log: 'Login to gain access',
    access1: 'To gain access to',
    access2: 'you need to be signed in',
    login: 'Login',
  },

  getinfo: 'Fetching information...',

  caseactiv: 'Main activity',

  coveredindex: {
    title: 'List of covered mares per stud',
    year: 'Year of the breeding season',
  },

  customer: {
    gen: 'General information',
    contacts: 'Contacts',
    horses: 'Horses',
    embryos: 'Embryos',
    semen: 'Semen',
    research: 'Research sheets',

    client: 'Client',
    horse: 'Horse',
    extra: 'Extra',
    amount: 'Amount',
    status: 'status',
    edit: 'Edited',
    made: 'Created',

    code: 'Code',
    donormare: 'Donor Mare',
    donorstal: 'Donor Stallion',
    location: 'Location & color',
    owner: 'Owner',
    transfer: 'Transferred on',
    export: 'Exported on',
    import: 'Imported on',
    last: 'Last invoice',
    edited: 'Edited on',
    madeon: 'Created on',
  },

  customerindex: {
    part: 'Private individuals',
    corp: 'Businesses',
    company: 'Company',
    first: 'Firstname',
    last: 'Lastname',
    made: 'Aangemaakt on',
    nr: 'VAT-nr',
  },

  error: {
    title: 'Something went wrong...',
    subtitle: "The page you are looking for, couldn't be found.",
    home: 'Home',
  },

  horse: {
    gen: 'General information',
    result: 'Lab results',
    pass: 'Passport',
    embryos: 'Embryos',
    semen: 'Semen',
    stay: 'Stays',
    trans: 'Transactions',
    research: 'Research sheets',

    client: 'Client',
    horse: 'Horse',
    extra: 'Extra',
    amount: 'Amount',
    status: 'status',
    edit: 'Edited',
    made: 'Created',

    code: 'Code',
    donormare: 'Donor Mare',
    donorstal: 'Donor Stallion',
    location: 'Location & color',
    owner: 'Owner',
    transfer: 'Transferred on',
    export: 'Exported on',
    import: 'Imported on',
    last: 'Last invoice',
    edited: 'Edited on',
    madeon: 'Created on',
  },

  mare: {
    add: 'Add',
    name: 'Name',
    type: 'Type',
    nr: 'Brandnumber',
    chip: 'Microchip',
    location: 'Location',
    owner: 'Owner',
    surrogate: 'Surrogate',
    made: 'Created',
    edit: 'Edited on',
    birth: 'Birthdate',
    filter: 'Filter on location',
    show: 'Show only surrogates',
    hide: 'Hide deceased horses',
  },

  stallion: {
    add: 'Add',
    name: 'Name',
    type: 'Type',
    nr: 'Brandnumber',
    chip: 'Microchip',
    location: 'Location',
    owner: 'Owner',
    surrogate: 'Surrogate',
    made: 'Created on',
    edit: 'Edited on',
    birth: 'Birthdate',
    filter: 'Filter on location',
    show: 'Show only Studs',
    hide: 'Hide deceased horses',
  },

  stud: {
    add: 'Add',
    name: 'Name',
    fam: 'Lineage',
    type: 'Type',
    nr: 'Brandnumber',
    chip: 'Microchip',
    location: 'Location',
    owner: 'Owner',
    surrogate: 'Surrogate',
    made: 'Created on',
    edit: 'Edited on',
    birth: 'Birthdate',
    filter: 'Filter on location',
    hide: 'Hide deceased horses',
  },

  surrogate: {
    add: 'Add',
    name: 'Name',
    embryo: 'Embryo',
    planted: '45 days',
    type: 'Type',
    nr: 'Brandnumber',
    chip: 'Microchip',
    location: 'Location',
    owner: 'Owner',
    surrogate: 'Surrogate',
    made: 'Created on',
    edit: 'Edited on',
    birth: 'Birthdate',
    filter: 'Filter on location',
    hide: 'Hide deceased horses',
  },

  embryo: {
    code: 'Code',
    donormare: 'Donor Mare',
    donorstal: 'Donor Stallion',
    location: 'Location & color',
    owner: 'Owner',
    transfer: 'Transferred on',
    export: 'Export on',
    import: 'Imported on',
    last: 'Last invoice',
    edited: 'Edited on',
    madeon: 'Created on',
  },
  appliedmed: {
    title: 'Administered medication',
    type: 'Type',
    prod: 'Product',
    lot: 'Batch number',
    horse: 'Horse',
    amount: 'Amount',
    date: 'Date',
    range: 'Date range',
  },

  deathhorse: {
    name: 'Name',
    nr: 'Brandnumber',
    type: 'Type',
    location: 'Location',
    owner: 'Owner',
    surrogate: 'Surrogate',
    chip: 'Microchip',
    made: 'Created on',
    edit: 'Edited on',
    birth: 'Birthdate',
    filtersex: 'Filter on sex',
    filterloc: 'Filter on location',
    showsur: 'Only show surrogates',
    showstal: 'Only show studs',
  },

  delivprod: {
    title: 'Delivered products',
    type: 'Type',
    prod: 'Product',
    lot: 'Batch number',
    sup: 'Supplier',
    amount: 'Amount',
    date: 'Date',
    reach: 'Date range',
  },

  gestation: {
    chip: 'Microchip:',
    ueln: 'UELN:',
    salt: 'Zoutleeuw,',
    title: 'Selection fields',
    sign: 'Select undersigned',
    lang: 'Select a language',
    back: 'back',
    print: 'print',
  },

  insemination: {
    title: 'Insemination evidence',
    client: 'Client',
    mare: 'Mare',
    name: 'Name:',
    ueln: 'UELN:',
    chip: 'Microchip:',
    stud: 'Stud',
    insem: 'Date insemination:',
    con: 'Date controle:',
    preg: 'Date planned birth:',
    title2: 'Add days to controle date',
    amount: 'Amount',
    back: 'back',
    print: 'print',
  },

  outprod: {
    title: 'Delivered products',
    type: 'Type',
    prod: 'Product',
    lot: 'Batch number',
    client: 'Client',
    amount: 'Amount',
    date: 'Date',
    horse: 'Horse',
    filter: 'Filter on modification',
    range: 'Date range',
  },

  semenstock: {
    title: 'Generate stock report',
    print: 'print',
    type: 'Filter on type',
    mod: 'Filter on stock modification',
    semen: 'Semen production range',
    range: 'Modification range',
  },

  surperloc: {
    name: 'Name',
    type: 'Type',
    nr: 'Brandnumber',
    chip: 'Microchip',
    location: 'Location',
    owner: 'Owner',
    surrogate: 'Surrogate',
    made: 'Created on',
    edit: 'Edited on',
    birth: 'Birthdate',
    filter: 'Filter on location',
    hide: 'Hide deceased horses',
  },

  fatcert: {
    emcode: 'Embryo code',
    embryo: 'Embryo',
    eminfo: 'Embryo info',
    chip: 'Microchip:',
    salt: 'Zoutleeuw,',
    title: 'Selection fields',
    sign: 'Select undersigned',
    lang: 'Select a language',
    flush: 'Date flush',
    ai: 'Date A.I.',
    back: 'back',
    print: 'print',
  },

  semencreate: {
    title: 'General',
    lot: 'batch',
    type: 'Semen batch type *',
    prod: 'Semen production date',
    import: 'Semen import date',
    invoice: 'Last invoice date',
    title2: 'Location',
    container: 'Nitrogen barrel *',
    cooker: 'Tube *',
    pos: 'Position *',
    straw: 'Amount of straws *',
    color: 'Straw color',
    title3: 'Quality parameters',
    conc: 'Concentration',
    moti: 'Motility',
    prog: 'Progressivity',
    velo: 'Velocity',
    dose: 'Xpail/dose',
    via: 'Viability',
    back: 'back',
    edit: 'Add',
    save: 'Save',
    req: 'this field is required',
  },
  semensee: {
    title: 'Semen batches',
    add: 'Add semen batch',
  },

  labosindex: {
    add: 'Add lab research',
    nlname: 'Research name - NL *',
    enname: 'Research name - EN',
    frname: 'Research name - FR',
    account: 'Account',
    price: 'Price',
    fixed: 'Fixed price',
    tax: 'VAT',
    edit: 'Edit',
    filter: 'Filter on VAT',
  },

  laboslabo: {
    semen: 'For this research I want to be able to select a semen location',
    embryo: 'For this research I want to be able to select an embryo location',
    horse: 'I want to be able to select a horse for this research',
    sex: 'with as sex',
  },

  locdialog: {
    stable: 'Stable',
    prefix: 'Prefix',
    name: 'Name location*',
    amount: 'Amount of places*',
    cancel: 'Cancel',
    save: 'Save',
    new: 'New location',
    edit: 'Edit location',
  },

  locinfo: {
    nodata: 'No horses found',
    close: 'Close',
    name: 'Name',
    type: 'Type',
    nr: 'Brandnumber',
    chip: 'Microchip',
    owner: 'Owner',
    birth: 'Birthdate',
  },

  nitroindex: {
    title: 'Nitrogen barrels',
    edit: 'Edit Container',
    delete: 'Delete container',
    nodata: 'No containers in the database',
    add: 'Add barrel',
    name: 'Name barrel',
    amount: 'Amount of tubes',
    edit2: 'Edit',
  },

  nitrodialog: {
    name: 'Name barrel',
    amount: 'Amount Tubes',
    cancel: 'Cancel',
    save: 'Save',
    new: 'New container',
    edit: 'Edit Container',
  },

  pensionindex: {
    add: 'Add guesthouse type',
    nlname: 'Guesthouse name - NL *',
    enname: 'Guesthouse name - EN',
    frname: 'Guesthouse name - FR',
    account: 'Account',
    price: 'Price/day',
    tax: 'VAT',
    edit: 'Edit',
    filter: 'Filter on VAT',
  },

  prodindex: {
    title: 'Products',
    add: 'Add product',
    name: 'Product name',
    type: 'Type',
    cnk: 'CNK',
    dose: 'Amount/batch',
    unit: 'Unit',
    wait: 'Waitingtime (Days)',
    tax: 'VAT',
    sup: 'Supplement (€)',
    edit: 'Edit',
    filter: 'Filter on type',
  },

  protindex: {
    add: 'Add protocol',
    title: 'Protocols',
    nlname: 'Protocol name - NL',
    enname: 'Protocol name - EN',
    frname: 'Protocol name - FR',
    account: 'Account',
    price: 'Price',
    fixed: 'Fixed price',
    tax: 'VAT',
    edit: 'Edit',
    filter: 'Filter on VAT',
  },

  protoprot: {
    semen: 'For this research I want to be able to select a semen location',
    embryo: 'For this research I want to be able to select an embryo location',
    horse: 'I want to be able to select a horse for this research',
    sex: 'with as sex',
    client: 'I want to be able to select a customer for this protocol',
    amount: 'For this protocol I want to enter a more specific amount',
    spec: 'specific amount for',
  },

  researchsheet: {
    nodata: 'No research sheets found',
    download: 'Download research sheet',
    title: 'Research sheets',
    name: 'Name',
    nr: 'Brandnumber',
    chip: 'Microchip',
    location: 'Location',
    birth: 'Birthdate',
    search: 'Search',
  },

  tenant: {
    title: 'Teamleader',
    text: 'Sign-in to your teamleader account to enable sync',
    login: 'Sign in',
  },

  uploadstock: {
    semen: 'Upload semen stock',
    stay: 'Upload stays',
  },

  stocksbatch: {
    details: 'Details',
    lot: 'Batches',
    mods: 'Mods',
    nr: 'Batch number',
    exp: 'Expiration date',
    deliv: 'Delivery date',
    sup: 'Supplier',
    buy: 'Purchase price',
    sell: 'Sale price',
    sellunit: 'Sale price/unit',
    ini: 'Initial amount',
    rest: 'remaining',
    last: 'last update',
    new: 'New batch',
    edit: 'Edit batch',
  },
  yearly: {
    back: 'back',
    download: 'download csv',
  },
};
