import { get, post } from '@/services/API';

export default {
  postInvoice: (kees, sheets) => post(`/api/teamleader/invoiceCase/${kees._id}`, sheets),
  postSemenInvoice: (selected) => post('/api/teamleader/invoiceSemen', { selected }),
  postICSIInvoice: (selected) => post('/api/teamleader/invoiceICSI', { selected }),
  syncCustomers: (type) => get(`/api/teamleader/${type}`),
  syncAccounts: () => get('/api/teamleader/ledgers'),
  authorize: () => get('/api/teamleader/authorize'),
  receiveCode: (code) => get(`/api/teamleader/receiveCode?code=${code}`),
};
