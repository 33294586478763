<template>
  <v-row class="ma-5">
    <v-col
      v-if="loading"
      cols="12"
    >
      {{ $t('getinfo') }}
    </v-col>
    <v-col
      v-for="stable of Object.keys(availableLocations)"
      v-else
      :key="stable"
      cols="12"
      md="4"
    >
      <available-locations-table
        :stable="stable"
        :available-locations="availableLocations[stable].locations"
      />
    </v-col>
  </v-row>
</template>

<script>
import { locationAPI } from '@/services/index.ts';
import AvailableLocationsTable from '@/components/availableLocation/Table';
import Vue from 'vue';

export default Vue.extend({
  name: 'AvailableLocationsOverview',
  components: { AvailableLocationsTable },
  data: () => ({
    availableLocations: {},
    headers: [],
    loading: false,
  }),
  beforeMount() {
    this.getAvailableLocations();
  },
  methods: {
    async getAvailableLocations() {
      this.loading = true;
      try {
        const { data } = await locationAPI.getAvailableLocations();
        this.availableLocations = data;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped>

</style>
