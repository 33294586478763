<template>
  <v-container class="mt-5">
    <span class="ml-2 headline">{{ title }}</span>
    <v-card
      outlined
      class="mt-3"
    >
      <v-form
        ref="form"
        v-model="valid"
        class="ma-5"
      >
        <v-layout justify-space-between>
          <v-row>
            <v-col
              cols="6"
              sm="6"
              md="6"
            >
              <v-file-input
                v-model="file"
                :label="$t('uploadform.file')"
                show-size
                :rules="required"
                :hint="$t('uploadform.uploadfile')"
                persistent-hint
                accept=".csv"
              />
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            class="mt-3"
            depressed
            :disabled="!valid"
            :loading="uploading"
            @click="$emit('upload', { file, type })"
          >
            {{ $t('uploadform.form') }}
          </v-btn>
        </v-layout>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { required } from '@/Helpers';

export default {
  name: 'Uploadform',
  props: {
    title: { type: String, required: true },
    type: { type: String, required: true },
    uploading: { type: Boolean },
    success: { type: Boolean },
  },
  data: () => ({
    valid: false,
    file: null,
  }),
  computed: { required },
  watch: {
    success() {
      if (this.success) {
        this.$refs.form.reset();
      }
    },
  },
};
</script>
