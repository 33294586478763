<template>
  <v-card
    class="mx-5 mt-5 mb-12"
    outlined
  >
    <mods
      :title="title"
      :filters="filters"
      :headers="headers"
    />
  </v-card>
</template>

<script>
import mods from '@/components/stock/Mods';
import i18n from '@/translations/i18n';
import { configAPI } from '@/services';

export default {
  components: { mods },
  data: () => ({
    title: i18n.t('outprod.title'),
    modsTypes: [],
    headers: [
      { text: i18n.t('outprod.type'), value: 'type', selected: true },
      { text: i18n.t('outprod.prod'), value: 'product', selected: true },
      { text: i18n.t('outprod.lot'), value: 'batch', selected: true },
      { text: i18n.t('outprod.client'), value: 'client', selected: true },
      { text: i18n.t('outprod.horse'), value: 'horse', selected: true },
      { text: i18n.t('outprod.amount'), value: 'amount', selected: true },
      { text: i18n.t('outprod.date'), align: 'end', value: 'createdAt', selected: true },
    ],
  }),
  computed: {
    filters() {
      return {
        type: {
          vTag: 'select',
          label: i18n.t('outprod.filter'),
          items: this.modsTypes,
          options: {
            multiple: true,
          },
        },
        horse: { vTag: 'searchHorse' },
        product: { vTag: 'searchProduct' },
        client: { vTag: 'searchCustomer' },
        dateRange: {
          vTag: 'dateRange',
          label: i18n.t('outprod.range'),
          from: 'from',
          to: 'to',
        },
      };
    },
  },
  mounted() { this.getModsConfig(); },
  methods: {
    async getModsConfig() {
      try {
        const { data: { types } } = await configAPI.getModConfig();
        this.modsTypes = types;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
