<template>
  <v-card
    class="ma-5"
    outlined
  >
    <v-card-title>{{ $t('tenant.title') }}</v-card-title>
    <v-card-text>{{ $t('tenant.text') }}</v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="isLoggedin"
        color="primary"
        @click="login"
      >
        {{ $t('tenant.login') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import teamleaderAPI from '@/services/TeamleaderAPI';

export default {
  name: 'Tenant',
  data() {
    return { isLoggedin: false };
  },
  watch: {
    '$route.query.code': {
      deep: true,
      handler(newCode) {
        if (!newCode) {
          this.isLoggedin = true;
        }
      },
    },
  },
  async mounted() {
    if (this.$route.query.code) {
      await teamleaderAPI.receiveCode(this.$route.query.code);
      this.$router.push('/settings/tenant');
    }
  },
  methods: { async login() {
    const { data: { url } } = await teamleaderAPI.authorize();
    window.location = url;
  } },
};
</script>

<style scoped>

</style>
