export const cases = {
  add: 'Add case',
  title: 'Cases',
  table: {
    headers: {
      ref: 'Ref',
      horse: 'Horse',
      client: 'Client',
      extra: 'Extra',
      activity: 'Main activity',
      amount: 'Amount',
      status: 'status',
      updatedOn: 'Edited',
      createdAt: 'Created',
    },
  },
  search: 'Search by reference or horse name',
  filters: {
    status: 'Filter on status',
    activity: 'Filter on activity',
    hideSurrogates: 'Hide surrogates',
    onlySurrogates: 'Only surrogates',
  },
};
