export const common = {
  loading: 'Bezig met laden...',
  noData: 'No data available',
  noneFound: 'No {item} found.',
  noneAdded: 'No {item} added.',
  unknown: 'Unknown',
  horse: 'Horse',
  horseTypes: {
    stallion: 'Stallion',
    stud: 'Stud',
    mare: 'Mare',
    surrogate: 'Surrogate',
  },
  actions: {
    filters: 'Filters',
    columns: 'Columns',
    close: 'Close',
    back: 'Back',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    search: 'Search',
    print: 'Print',
  },
  container: 'Container',
  tube: 'Tube',
  search: {
    stud: 'Search stud',
    stallion: 'Search stallion',
    surrogate: 'Search surrogate',
    mare: 'Search mare',
    horse: 'Search horse',
  },
  appnav: {
    logout: 'LogoutLogout',
    client: 'Clients',
    horse: 'Horses',
    stallion: 'Stallions',
    mare: 'Mares',
    surrogate: 'Surrogates',
    stud: 'Studs',
    meds: 'Medication',
    fert: 'Fertility',
    icsi: 'ICSI',
    semen: 'Semen',
    case: 'Cases',
    rap: 'Reports',
    locoverview: 'Overview free locations',
    locsur: 'Surrogates per location',
    dead: 'Passed away horses',
    stock: 'Semen stock',
    deliv: 'Delivered products',
    out: 'Outgoing products',
    apply: 'Applied medication',
    yearly: 'Yearly reports',
    reg: 'Register present animals',
    list: 'List covered mares',
    transper: 'Transactions per stallion owner',
    location: 'Locations',
    manage: 'Management',
    container: 'Nitrogen barrel',
    research: 'Download research sheets',
    product: 'Products',
    prot: 'Protocols',
    labo: 'Labo researches',
    pension: 'guesthouse types',
    upload: 'Upload',
    tenant: 'Tenant',
  },
  nav: {
    logout: 'Logout',
    client: 'Clients',
    horse: {
      main: 'Horses',
      stallion: 'Stallions',
      mare: 'Mares',
      surrogate: 'Surrogates',
      stud: 'Studs',
    },
    medication: 'Medication',
    fertility: {
      main: 'Fertility',
      icsi: 'ICSI',
      semen: 'Semen',
    },
    case: 'Cases',
    reports: {
      main: 'Reports',
      locationOverview: 'Overview free locations',
      surrogateByLocation: 'Surrogates per location',
      deceased: 'Deceased horses',
      semenStock: 'Semen stock',
      deliveredProducts: 'Delivered products',
      outgoingProducts: 'Outgoing products',
      appliedMedication: 'Applied medication',
      yearlyReports: 'Yearly reports',
      presenceRegister: 'Present animals register',
      deckedMares: 'List decked mares',
      stallionOwnerTransactions: 'Transactions per stallion owner',
    },
    config: {
      main: 'Management',
      locations: 'Locations',
      nitrogenContainers: 'Nitrogen vessels',
      downloadResearchSheet: 'Download research sheets',
      products: 'Products',
      protocols: 'Protocols',
      labs: 'Labo researches',
      pensionTypes: 'Pension types',
      upload: 'Upload',
      tenant: 'Tenant',
    },
  },
};
