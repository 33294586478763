<template>
  <v-autocomplete
    v-model="selected"
    :menu-props="{closeOnContentClick:true}"
    prepend-inner-icon="mdi-magnify"
    :search-input.sync="searchString"
    :clearable="clearable"
    :items="entries"
    hide-no-data
    hide-details
    :item-text="searchKey"
    :label="label"
    return-object
    :outlined="outlined"
    :dense="dense"
    :loading="loading"
    no-filter
    @click:clear="clear"
  >
    <template #item="{ item }">
      <v-list-item @click="emit(item)">
        <v-list-item-content>
          <v-list-item-title>{{ format ? format(item) : item }}</v-list-item-title>
          <v-list-item-subtitle v-if="searchKey === 'microchip'">
            {{ $t('basicsearch.chip') }} {{ item.microchip }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="searchKey === 'surrogate_uid'">
            {{ $t('basicsearch.nr') }} {{ item.surrogate_uid }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="searchKey === 'reference'">
            {{ ownerName(item.customer) }} - {{ horseName(item.horse) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { capitalize, horseName, ownerName } from '@/Helpers';

export default {
  props: {
    endpoint: { type: Function, required: true },
    type: { type: String, default: undefined },
    label: { type: String, required: true },
    searchKey: { type: String, required: true },
    format: { type: Function, default: undefined },
    getSpecific: { type: Function, default: undefined },
    clearable: { type: Boolean },
    outlined: { type: Boolean },
    dense: { type: Boolean },
  },
  data: () => ({
    loading: false,
    entries: [],
    search: null,
    selected: null,
  }),
  computed: {
    searchString: {
      get() {
        return this.search;
      },
      set(newValue) {
        this.search = newValue ? newValue.toString() : newValue;
      },
    },
  },
  watch: {
    search(val) {
      if (val && val !== this.selected) this.querySelections(val);
    },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        if (this.$route.query[this.searchKey]) {
          this.getSelectedFromUrl({ [this.searchKey]: this.$route.query[this.searchKey] });
        }
      },
    },
  },
  methods: {
    capitalize,
    horseName,
    ownerName,
    clear() {
      this.selected = {};
      this.$emit('select', undefined);
    },
    emit(item) {
      this.$emit('select', item);
      this.selected = item;
    },
    async getSelectedFromUrl(query) {
      this.loading = true;
      try {
        const result = await this.getSpecific(query);
        if (result) {
          this.entries = [result];
          this.selected = result;
        } else {
          this.entries = [];
          this.selected = {};
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async querySelections(v) {
      this.loading = true;
      try {
        this.entries = await this.endpoint(v);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
