export const customers = {
  general: {},
  horses: {
    table: {
      name: 'Naam paard',
      familyTree: 'Stamboom',
      type: 'Type paard',
    },
  },
  embryos: {},
  semen: {},
  researchFiles: {},
};
