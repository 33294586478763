<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>{{ $t('semeninvoice.bill') }} {{ ownerName(client) }}</v-card-title>
      <v-card-text style="max-height: 600px;">
        <v-form
          ref="form"
          v-model="valid"
          class="mt-5"
        >
          <semen-batch
            v-for="batch in selected"
            :key="batch._id"
            :batch="batch"
            :global-end-invoice="globalEndInvoice"
            @end-date-selected="globalEndInvoice = $event"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          {{ $t('semeninvoice.cancel') }}
        </v-btn>
        <v-btn
          color="success"
          text
          :loading="executing"
          :disabled="!valid"
          @click="invoiceSemen"
        >
          {{ $t('semeninvoice.bill') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SemenBatch from '@/components/semenCollection/invoice/SemenBatch';
import { ownerName } from '@/Helpers';
import { teamleaderAPI } from '@/services';

export default {
  components: { SemenBatch },
  props: {
    dialog: { type: Boolean },
    selected: {
      type: Array,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    client: null,
    valid: false,
    executing: false,
    globalEndInvoice: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected.length) {
          this.client = this.selected[0].owner;
        }
      },
      deep: true,
    },
  },
  methods: {
    ownerName,
    close() {
      this.$refs.form.reset();
      this.endInvoice = null;
      this.$emit('close', !this.dialog);
    },
    async invoiceSemen() {
      this.executing = true;
      try {
        await this.$store.dispatch('errorMessage/resetError');
        await teamleaderAPI.postSemenInvoice(this.selected);
        this.$emit('executed', this.action);
        this.close();
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.executing = false;
      }
    },
  },
};
</script>
