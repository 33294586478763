<template>
  <div>
    <v-dialog
      v-model="valueDialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('valuedialog.span') }}</span>
        </v-card-title>
        <v-card-text>
          <span v-if="noParams">
            {{ $t('valuedialog.params') }}
          </span>
          <v-row>
            <v-col
              v-if="semenCollection.concentration"
              cols="6"
              sm="4"
            >
              <v-text-field
                :value="semenCollection.concentration"
                :label="$t('valuedialog.conc')"
                suffix="M/ml"
                readonly
              />
            </v-col>
            <v-col
              v-if="semenCollection.motility"
              cols="6"
              sm="4"
            >
              <v-text-field
                :value="semenCollection.motility"
                :label="$t('valuedialog.moti')"
                suffix="%"
                readonly
              />
            </v-col>
            <v-col
              v-if="semenCollection.progress"
              cols="6"
              sm="4"
            >
              <v-text-field
                :value="semenCollection.progress"
                :label="$t('valuedialog.prog')"
                suffix="%"
                readonly
              />
            </v-col>
            <v-col
              v-if="semenCollection.velocity"
              cols="6"
              sm="4"
            >
              <v-text-field
                :value="semenCollection.velocity"
                :label="$t('valuedialog.velo')"
                suffix="Mic/sec"
                readonly
              />
            </v-col>
            <v-col
              v-if="semenCollection.pail"
              cols="6"
              sm="4"
            >
              <v-text-field
                :value="semenCollection.pail"
                :label="$t('valuedialog.dose')"
                suffix="Paill/dosis"
                readonly
              />
            </v-col>
            <v-col
              v-if="semenCollection.viability"
              cols="6"
              sm="4"
            >
              <v-text-field
                :value="semenCollection.viability"
                :label="$t('valuedialog.via')"
                suffix="%"
                readonly
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            {{ $t('valuedialog.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { formatDate } from '@/Helpers';

export default {
  props: ['semenCollection', 'valueDialog'],
  computed: {
    noParams() {
      return [
        this.semenCollection.concentration,
        this.semenCollection.motility,
        this.semenCollection.progress,
        this.semenCollection.pail,
        this.semenCollection.velocity,
        this.semenCollection.viability].every((el) => !el);
    },
  },
  methods: {
    formatDate,
    closeDialog() {
      this.$emit('close-dialog', false);
    },
  },
};
</script>
