import Case from '@/views/case/Case';
import CaseIndex from '@/views/case/index';
import Customer from '@/views/customer/Customer';
import CustomerIndex from '@/views/customer/Index';
import Home from '@/views/Home';
import AuthRoot from '@/views/AuthRoot';
import Horse from '@/views/horse/Horse';
import Mare from '@/views/horse/Mare';
import Stallion from '@/views/horse/Stallion';
import Studhorse from '@/views/horse/Studhorse';
import Surrogate from '@/views/horse/Surrogate';
import CreateICSI from '@/views/icsi/Create';
import ICSIEmbryos from '@/views/icsi/Embryos';
import ICSI from '@/views/icsi/index';
import AppliedMedication from '@/views/report/AppliedMedication';
import DeathHorse from '@/views/report/DeathHorse';
import DeliveredProducts from '@/views/report/DeliveredProducts';
import ReportLocation from '@/views/report/SurrogatePerLocation';
import OutgoingProducts from '@/views/report/OutgoingProducts';
import labos from '@/views/settings/labos';
import labo from '@/views/settings/labos/labo';
import Location from '@/views/settings/locations';
import NitrogenContainer from '@/views/settings/nitrogenContainer';
import PensionIndex from '@/views/settings/pension/index';
import Pension from '@/views/settings/pension/Pension';
import products from '@/views/settings/products';
import product from '@/views/settings/products/product';
import protocols from '@/views/settings/protocols';
import protocol from '@/views/settings/protocols/protocol';
import ResearchSheets from '@/views/settings/ResearchSheets';
import batch from '@/views/stock/batch';
import Stock from '@/views/stock/index';
import uploadSemenStock from '@/views/settings/uploadSemenStock';
import tenantConfig from '@/views/settings/tenant';
import SemenHorses from '@/views/semen/index';
import Semen from '@/views/semen/Semen';
import CreateSemen from '@/views/semen/Create';
import SemenStock from '@/views/report/SemenStock';
import InsemnationReport from '@/views/report/Insemination.report';
import VetcertificateReport from '@/views/report/Vetcertificate.report';
import TransactionPerStallionOwner from '@/views/transaction/transactionPerStallionOwner';
import YearlyReport from '@/views/yearlyReport/index.vue';
import TypeYearlyReportTable from '@/views/yearlyReport/tables/TypeYearlyReportTable';
import Vue from 'vue';
import VueRouter from 'vue-router';
import GestationReport from '@/views/report/Gestation.report';
import LiveStockRegister from '@/views/livestock-register/LiveStockRegister';
import CoveredMaresList from '@/views/covered-mares/index';
import AvailableLocationsOverview from '@/views/availableLocations/index';
import LoggedOut from '@/views/auth/LoggedOut';
import ErrorPage404 from '@/views/errors/404';
import { authGuard } from '@/auth';

Vue.use(VueRouter);

const routes = [
  // Public routes
  {
    component: LoggedOut,
    name: 'logged-out',
    path: '/logged-out',
  },
  {
    path: '/not-found',
    component: ErrorPage404,
    name: 'Not Found',
  },
  // Protected routes
  {
    beforeEnter: authGuard,
    component: AuthRoot,
    path: '',
    children: [
      {
        component: Home,
        name: 'home',
        path: '/',
      },
      {
        component: ICSI,
        name: 'ICSI',
        path: '/icsi',
      },
      {
        component: CreateICSI,
        path: '/icsi/create',
        props: true,
      },
      {
        component: ICSIEmbryos,
        path: '/icsi/:id',
        props: true,
      },
      {
        component: SemenHorses,
        name: 'Semen',
        path: '/semen',
      },
      {
        component: Semen,
        path: '/semen/:id',
        props: true,
      },
      {
        component: CreateSemen,
        path: '/semen/create/:id',
        props: true,
      },
      {
        component: Stallion,
        name: 'hengst',
        path: '/horse/stallion',
      },
      {
        component: Mare,
        name: 'merrie',
        path: '/horse/mare',
      },
      {
        component: Surrogate,
        name: 'draagmoeder',
        path: '/horse/surrogate',
      },
      {
        component: Studhorse,
        name: 'dekhengsten',
        path: '/horse/studhorse',
      },
      {
        component: Horse,
        path: '/horse/create',
        props: false,
      },
      {
        component: Horse,
        path: '/horse/:id',
        props: true,
      },
      {
        component: CustomerIndex,
        name: 'customer',
        path: '/customer',
      },
      {
        component: Customer,
        name: 'customer page',
        path: '/customer/:id',
        props: true,
      },
      {
        component: ReportLocation,
        name: 'location report',
        path: '/report/location',
      },
      {
        component: DeathHorse,
        name: 'death horses report',
        path: '/report/death-horses',
      },
      {
        component: SemenStock,
        path: '/report/semen-stock',
      },
      {
        component: DeliveredProducts,
        name: 'Stock delivered products',
        path: '/report/delivered-products',
      },
      {
        component: OutgoingProducts,
        name: 'Stock outgoing products',
        path: '/report/outgoing-products',
      },
      {
        component: AppliedMedication,
        name: 'Stock applied medication',
        path: '/report/applied-medication',
      },
      {
        component: InsemnationReport,
        name: 'Insemination report',
        path: '/report/insemination',
      },
      {
        component: VetcertificateReport,
        name: 'Vet-certificate report',
        path: '/report/vetcertificate',
      },
      {
        component: GestationReport,
        name: 'Gestation report',
        path: '/report/gestation',
      },
      {
        component: Location,
        name: 'location',
        path: '/settings/location',
      },
      {
        component: NitrogenContainer,
        name: 'Stikstof vaten',
        path: '/settings/nitrogen-container',
      },
      {
        component: ResearchSheets,
        name: 'Onderzoeksfiches',
        path: '/settings/research-sheets',
      },
      {
        component: products,
        name: 'Producten overview',
        path: '/settings/products',
      },
      {
        component: product,
        path: '/settings/product/:id',
        props: true,
      },
      {
        component: protocols,
        name: 'Protocols overview',
        path: '/settings/protocols',
      },
      {
        component: protocol,
        path: '/settings/protocol/:id',
        props: true,
      },
      {
        component: uploadSemenStock,
        path: '/settings/uploadSemenStock',
      },
      {
        component: tenantConfig,
        path: '/settings/tenant',
      },
      {
        component: labos,
        name: 'Labo overview',
        path: '/settings/labos',
      },
      {
        component: labo,
        path: '/settings/labo/:id',
        props: true,
      },
      {
        component: Stock,
        name: 'Stock overview',
        path: '/stock',
      },
      {
        component: batch,
        path: '/stock/:id',
        props: true,
      },
      {
        component: CaseIndex,
        name: 'Cases overview',
        path: '/cases',
      },
      {
        component: Case,
        path: '/case/:id',
        props: true,
      },
      {
        component: PensionIndex,
        name: 'Pension overview',
        path: '/settings/pension',
      },
      {
        component: Pension,
        path: '/settings/pension/:id',
        props: true,
      },
      {
        component: YearlyReport,
        path: '/yearlyReport',
      },
      {
        component: TypeYearlyReportTable,
        path: '/yearlyReport/:type',
        name: 'type-yearly-report-table',
      },
      {
        component: LiveStockRegister,
        path: '/livestock',
        name: 'Livestock register',
      },
      {
        component: CoveredMaresList,
        path: '/covered-mares',
        name: 'Covered mares list',
      },
      {
        component: TransactionPerStallionOwner,
        path: '/transactions-per-stallion-owner',
        name: 'Transactions per stallion owner',
      },
      {
        component: AvailableLocationsOverview,
        path: '/report/available-locations',
        name: 'Available locations',
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
});

export default router;
