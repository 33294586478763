<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <v-card
      class="ma-5"
      outlined
    >
      <v-toolbar flat>
        <v-toolbar-title>{{ $t('nitroindex.title') }}</v-toolbar-title>
      </v-toolbar>
      <v-data-table
        class="ma-5"
        :headers="headers"
        :items="containers"
        :server-items-length="totalContainers"
        :options.sync="options"
        :loading="loading"
        :loading-text="$t('common.loading')"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
      >
        <template #item="props">
          <tr>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.available_places }}</td>
            <td class="text-right d-print-none">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    dark
                    color="primary"
                    class="mr-2"
                    @click="editContainer(props.item)"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('nitroindex.edit') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    dark
                    color="primary"
                    @click="deleteContainer(props.item)"
                    v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>{{ $t('nitroindex.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
        <template #no-data>
          {{ $t('nitroindex.nodata') }}
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5"
      depressed
      @click="createNitrogen"
    >
      {{ $t('nitroindex.add') }}
      <v-icon right>
        mdi-plus
      </v-icon>
    </v-btn>
    <nitrogen-dialog
      :edit="edit"
      :dialog="dialog"
      :selected-nitrogen="selectedNitrogen"
      @close="dialog = $event"
      @saved="newNitrogen = $event"
    />
  </v-layout>
</template>
<script>
import { compareRouteAndPush } from '@/Helpers';
import i18n from '@/translations/i18n';
import nitrogenContainerAPI from '@/services/NitrogenContainerAPI.js';
import NitrogenDialog from '@/views/settings/nitrogenContainer/NitrogenDialog';

export default {
  components: { NitrogenDialog },
  data: () => ({
    edit: false,
    dialog: false,
    loading: false,
    headers: [
      { text: i18n.t('nitroindex.name'), value: 'name' },
      { text: i18n.t('nitroindex.amount'), value: 'available_places' },
      { text: i18n.t('nitroindex.edit2'), value: 'action', align: 'end', sortable: false },
    ],
    options: {},
    containers: [],
    totalContainers: 0,
    selectedNitrogen: {},
    newNitrogen: undefined,
    sortBy: 'name',
    sortDesc: false,
  }),
  watch: {
    newNitrogen: {
      deep: true,
      handler() { this.getContainers(); },
    },
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.compareRouteAndPush({
          ...this.$route.query,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
        }, this.$route, this.$router);
      },
    },
    '$route.query': {
      deep: true,
      handler() {
        if (!this.$route.query.page && !this.$route.query.limit) {
          this.$router.replace({
            query: {
              page: 1,
              limit: this.options.itemsPerPage,
              sortBy: this.options.sortBy,
              sortDesc: this.options.sortDesc,
              ...this.$route.query,
            },
          });
        } else { this.getContainers(); }
      },
    },
  },
  methods: {
    compareRouteAndPush,
    createNitrogen() {
      this.edit = false;
      this.selectedNitrogen = {};
      this.dialog = true;
    },
    editContainer(nitrogen) {
      this.edit = true;
      this.selectedNitrogen = nitrogen;
      this.dialog = true;
    },
    async getContainers() {
      this.loading = true;
      try {
        const { data: { results, total } } = await nitrogenContainerAPI.getNitrogenContainers(this.$route.query);
        this.containers = results;
        this.totalContainers = total;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    async deleteContainer(item) {
      try {
        this.loading = true;
        await this.$store.dispatch('errorMessage/resetError');
        await nitrogenContainerAPI.deleteNitrogenContainer(item._id);
        await this.getContainers();
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
