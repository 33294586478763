<template>
  <v-layout
    column
    fill-height
  >
    <v-row class="mt-0 mx-0 mb-2">
      <v-col
        cols="12"
        class="information d-flex align-center"
      >
        <span class="ml-2">{{ $t('typeselect') }}</span>
      </v-col>
    </v-row>
    <v-row class="mx-5 mb-5">
      <v-col
        cols="12"
        class="pa-0"
      >
        <v-select
          v-model="reports"
          dense
          multiple
          clearable
          outlined
          hide-details
          item-text="name"
          item-value="name"
          return-object
          label="Rapport type"
          :items="allReportTypes"
          :menu-props="{ top: true, offsetY: true }"
          @input="selectReport"
        />
      </v-col>
    </v-row>
    <template v-if="reports && reports.length">
      <v-alert
        v-for="(report, idx) in reports"
        :key="idx"
        class="mx-5"
        text
        dense
        border="left"
        :type="generateAlertType(report)"
        style="width: fit-content"
      >
        <span v-html="generateAlertText(report)" />
      </v-alert>
    </template>
  </v-layout>
</template>

<script>
import { configAPI } from '@/services';

export default {
  name: 'ReportTypeSelector',
  props: {
    reportType: { type: Array, default: undefined },
    parameters: { type: Array, default: () => ([]) },
  },
  data: () => ({
    reports: null,
    allReportTypes: [],
    requiredString: '',
    optionalString: '',
    alertType: 'info',
  }),
  mounted() {
    this.getProtocolReportTypes();
    if (this.reportType) {
      this.reports = this.reportType;
    }
  },
  methods: {
    convertType(type) {
      switch (type) {
      case 'semenCollection':
        return 'sperma';
      case 'horse':
        return 'paard';
      default:
        return type;
      }
    },
    generateReportObject(report) {
      if (report && report.fields && report.fields.length) {
        const { required, optional, fields } = report.fields && report.fields.reduce((rv, curr) => {
          if (curr.required) {
            rv.required.push(`${this.convertType(curr.type)}`);
            rv.fields.push(curr.type.toLowerCase());
          } else {
            rv.optional.push(`${this.convertType(curr.type)}`);
          }
          return rv;
        }, { required: [], optional: [], fields: [] });
        return { required, optional, fields };
      }

      return { required: [], optional: [], fields: [] };
    },
    generateRequiredFields() {
      if (this.reports) {
        const req = this.reports.reduce((rv, curr) => [...rv, ...curr.fields.filter((field) => field.required)], []);
        this.$emit('emit-mandatory-fields', [...new Set(req)]);
      }
    },
    selectReport(reports) {
      this.$emit('select-type', reports);
      this.generateRequiredFields();
      if (!reports.length) {
        this.$emit('emit-mandatory-fields', []);
      }
    },
    generateAlertText(report) {
      return (
        `Voor een correct ${report.name} moeten volgende velden verplicht actief zijn:
        <b>${this.reportFieldsString(report).requiredString}</b>${this.reportFieldsString(report).optionalString
          ? ` en optioneel: ${this.reportFieldsString(report).optionalString}.`
          : '.'}`);
    },
    generateAlertType(report) {
      const { fields } = this.generateReportObject(report);
      return fields.every((field) => this.parameters.find(({ elementModel }) => elementModel.toLowerCase() === field))
        ? 'info' : 'error';
    },
    reportFieldsString(report) {
      let requiredString = '';
      let optionalString = '';

      const { required, optional } = this.generateReportObject(report);

      if (required && required.length) {
        required.forEach((string, idx) => {
          requiredString += `${string}${idx + 1 === required.length ? '' : ', '}`;
        });
      }

      if (optional && optional.length) {
        optional.forEach((string, idx) => {
          optionalString += `${string}${idx + 1 === optional.length ? '' : ', '}`;
        });
      }

      return { requiredString, optionalString };
    },
    async getProtocolReportTypes() {
      try {
        const { data: { reportTypes } } = await configAPI.getProtocolReportTypes();
        this.allReportTypes = reportTypes;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>

<style scoped>
.information {
  background-color: #f6f6f6;
  border-left: solid 4px rgba(0, 0, 0, .12);
}
</style>
