<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="690"
  >
    <v-card>
      <v-card-title>{{ $t('createbatch.title') }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="batch.lotNumber"
                  :rules="required"
                  type="text"
                  :label="$t('createbatch.nr')"
                />
              </v-col>
              <v-col cols="6">
                <date-picker
                  :date="batch.expirationDate"
                  :label="$t('createbatch.exp')"
                  :required="true"
                  @select-date="batch.expirationDate = $event"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="batch.supplier"
                  type="text"
                  :label="$t('createbatch.sup')"
                />
              </v-col>
              <v-col cols="6">
                <date-picker
                  :date="batch.deliveryDate"
                  :label="$t('createbatch.deliv')"
                  :required="true"
                  @select-date="batch.deliveryDate = $event"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="batch.buyInPrice"
                  type="number"
                  :label="$t('createbatch.buy')"
                  :rules="requiredNumber"
                  prefix="€"
                  placeholder="0.00"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="batch.sellingPricePerUnit"
                  type="number"
                  :label="$t('createbatch.sell')"
                  :rules="requiredNumber"
                  prefix="€"
                  placeholder="0.00"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="batch.initialAmount"
                  :rules="requiredNumber"
                  type="number"
                  :label="$t('createbatch.ini')"
                  placeholder="0"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="close"
        >
          {{ $t('createbatch.cancel') }}
        </v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          text
          @click="save"
        >
          {{ $t('createbatch.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from '@/components/DateSelector/DatePicker';
import { required, requiredNumber } from '@/Helpers';
import { stockAPI } from '@/services';

export default {
  name: 'CreateStockBatchDialog',
  components: { DatePicker },
  props: {
    productId: { type: String, required: true },
    dialog: { type: Boolean, default: false },
  },
  data: () => ({
    valid: false,
    batch: {},
  }),
  computed: { required, requiredNumber },
  methods: {
    close() {
      this.$refs.form.reset();
      this.$emit('close', false);
    },
    async save() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        this.loading = true;
        await stockAPI.postStock({ ...this.batch, product: this.productId });
        this.$emit('saved', true);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.close();
        this.loading = false;
      }
    },
  },
};
</script>
