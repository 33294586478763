
import { formatDate, printName } from '@/Helpers';
import notificationAPI from '@/services/NotificationAPI';
import Vue from 'vue';
import i18n from '@/translations/i18n';

export default Vue.extend({
  name: 'ToExpireLabsBlock',
  data: () => ({
    headers: [
      { text: i18n.t('expirelabblock.lab'), value: 'name', sortable: false },
      { text: i18n.t('expirelabblock.horse'), value: 'horse', sortable: false },
      { text: i18n.t('expirelabblock.client'), value: 'owner', sortable: false },
      { text: i18n.t('expirelabblock.expiration'), value: 'expirationDate', sortable: false },
      { sortable: false },
    ],
    labNotifications: [],
  }),
  beforeMount() {
    this.getNotifications();
  },
  methods: {
    formatDate,
    printName,
    async getNotifications() {
      try {
        const { data: { results } } = await notificationAPI.getAll({
          active: true,
          'sortBy[]': 'expirationDate',
          'sortDesc[]': false,
          limit: 5,
        });

        this.labNotifications = results;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      }
    },
    async deleteNotification(notification) {
      try {
        await notificationAPI.put({ ...notification, active: false });
        await this.getNotifications();
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      }
    },
  },
});
