<template>
  <v-card
    class="mx-5 mt-5 mb-12"
    outlined
  >
    <mods
      :title="title"
      :filters="filters"
      :headers="headers"
    />
  </v-card>
</template>

<script>
import mods from '@/components/stock/Mods';
import i18n from '@/translations/i18n';

export default {
  components: { mods },
  data: () => ({
    title: i18n.t('appliedmed.title'),
    headers: [
      { text: i18n.t('appliedmed.type'), value: 'type', selected: true },
      { text: i18n.t('appliedmed.prod'), value: 'product', selected: true },
      { text: i18n.t('appliedmed.lot'), value: 'batch', selected: true },
      { text: i18n.t('appliedmed.horse'), value: 'horse', selected: true },
      { text: i18n.t('appliedmed.amount'), value: 'amount', selected: true },
      { text: i18n.t('appliedmed.date'), align: 'end', value: 'createdAt', selected: true },
    ],
  }),
  computed: {
    filters() {
      return {
        horse: { vTag: 'searchHorse' },
        product: { vTag: 'searchProduct' },
        dateRange: {
          vTag: 'dateRange',
          label: i18n.t('appliedmed.range'),
          from: 'from',
          to: 'to',
        },
      };
    },
  },
};
</script>
