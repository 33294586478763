<template>
  <v-card
    class="mx-5 mt-5"
    outlined
  >
    <v-row class="ma-2">
      <v-col style="display: flex; align-items: center; justify-content: center; flex-direction: column">
        <span>{{ $t('sementotal.span1') }}</span>
        <h2>{{ initial || 0 }}</h2>
      </v-col>
      <v-col style="display: flex; align-items: center; justify-content: center; flex-direction: column">
        <span>{{ $t('sementotal.span2') }}</span>
        <h2>{{ reduction || 0 }}</h2>
      </v-col>
      <v-col style="display: flex; align-items: center; justify-content: center; flex-direction: column">
        <span>{{ $t('sementotal.span3') }}</span>
        <h2>{{ current || 0 }}</h2>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'SemenStockTotals',
  props: {
    initial: { type: Number, default: 0 },
    reduction: { type: Number, default: 0 },
    current: { type: Number, default: 0 },
  },
};
</script>
