export const customers = {
  general: {},
  horses: {
    table: {
      name: 'Name horse',
      familyTree: 'Family tree',
      type: 'Type horse',
    },
  },
  embryos: {},
  semen: {},
  researchFiles: {},
};
