const errorMessage = {
  namespaced: true,

  state: () => ({
    message: '',
    code: 0,
  }),

  actions: {
    setError({ commit }, error) {
      commit('setError', { message: error.message, code: error.code });
    },
    resetError({ commit }) {
      commit('resetError');
    },
  },

  mutations: {
    setError(state, { message, code }) {
      state.message = message;
      state.code = code;
    },
    resetError(state) {
      state.message = '';
      state.code = 0;
    },
  },
};

export default errorMessage;
