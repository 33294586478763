export const config = {
  locations: {
    title: 'Locations',
    add: 'Add location',
    table: {
      headers: {
        stable: 'Stable',
        name: 'Locations name',
        places: '# places',
        amountOfHorses: '# horses',
      },
      tooltips: {
        delete: 'Delete location',
        edit: 'Edit location',
        presentHorses: 'Present horses',
      },
    },
  },
};
