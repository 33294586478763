<template>
  <v-card
    class="mx-5 mt-5 mb-12"
    outlined
  >
    <v-toolbar flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-col class="pr-0">
        <search-product
          :id="$route.query._id"
          :clearable="true"
          :dense="true"
          @emit-product="updateSearch"
        />
      </v-col>
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetchStock"
        @fetching="loading = $event"
        @fetchedResults="products = $event"
        @fetchedTotal="totalProducts = $event"
        @fetchedRestProps="getRemainingValue"
      />
      <columns
        :headers="headers"
        @emit-headers="filteredHeaders = $event"
      />
    </v-toolbar>
    <v-card
      class="mx-5"
      flat
    >
      <v-container fluid>
        <v-row>
          <v-col
            cols="6"
            :md="3"
            class="d-flex align-center"
          >
            {{ $t('stocktable.buy') }}
          </v-col>
          <v-col
            cols="6"
            :md="3"
          >
            <h3>€{{ totalRemainingBuyValue }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            :md="3"
            class="d-flex align-center"
          >
            {{ $t('stocktable.sell') }}
          </v-col>
          <v-col
            cols="6"
            :md="3"
          >
            <h3>€{{ totalRemainingSellValue }}</h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-data-table
      :headers="filteredHeaders"
      :items="products"
      :server-items-length="totalProducts"
      :loading="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :options.sync="options"
      :loading-text="$t('common.loading')"
      class="ma-5"
      multi-sort
    >
      <template #no-data>
        {{ $t('stocktable.nodata') }}
      </template>
      <template #item="props">
        <tr
          class="clickable"
          @click="openStockProductPage(props.item._id)"
        >
          <td v-if="showColumn('name')">
            {{ props.item.name }}
          </td>
          <td v-if="showColumn('type')">
            {{ props.item.type }}
          </td>
          <td v-if="showColumn('CNK')">
            {{ props.item.CNK }}
          </td>
          <td v-if="showColumn('dosage')">
            {{ props.item.dosage }}
          </td>
          <td v-if="showColumn('outgoingUnit')">
            {{ props.item.outgoingUnit }}
          </td>
          <td v-if="showColumn('tax')">
            {{ props.item.tax }}
          </td>
          <td v-if="showColumn('expirationDate')">
            {{ formatDate(props.item.expirationDate) }}
          </td>
          <td v-if="showColumn('waitingTime')">
            {{ props.item.waitingTime }}
          </td>
          <td
            v-if="showColumn('supplementAdministration')"
            class="text-right"
          >
            €{{ props.item.supplementAdministration ? props.item.supplementAdministration.toFixed(2) : 0.00 }}
          </td>
          <td
            v-if="showColumn('sellValue')"
            class="text-right"
          >
            €{{ props.item.remainingSellvalue }}
          </td>
          <td
            v-if="showColumn('buyinValue')"
            class="text-right"
          >
            €{{ props.item.remainingBuyValue }}
          </td>
          <td
            v-if="showColumn('remaining')"
            class="text-right"
          >
            {{ props.item.remaining }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import SearchProduct from '@/components/search/SearchProduct';
import Filters from '@/components/filters/Filters';
import Columns from '@/components/filters/Columns';
import { formatDate, required, compareRouteAndPush } from '@/Helpers';
import { stockAPI } from '@/services';

export default {
  components: { Filters, Columns, SearchProduct },
  props: {
    title: { type: String, default: 'Stock' },
    headers: { type: Array, required: true },
    filters: { type: Object, required: true },
    sortBy: { type: Array, default: () => ['name'] },
    update: { type: Boolean },
  },
  data: (data) => ({
    loading: false,
    sortDesc: false,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    totalProducts: 0,
    totalRemainingBuyValue: 0,
    totalRemainingSellValue: 0,
    products: [],
    filteredHeaders: [],
  }),
  computed: { required },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush({
          ...this.$route.query,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
        }, this.$route, this.$router);
      },
    },
    update() {
      if (this.update) this.getAllStock();
      this.$emit('updated', false);
    },
  },
  methods: {
    formatDate,
    compareRouteAndPush,
    showColumn(col) { return this.headers.find((header) => header.value === col).selected; },
    openStockProductPage(id) {
      document.body.style.cursor = 'default';
      this.$router.push(`/stock/${id}`);
    },
    updateSearch(product) {
      this.compareRouteAndPush({
        ...this.$route.query,
        _id: product ? product._id : undefined,
      }, this.$route, this.$router);
    },
    fetchStock(URLparams) { return stockAPI.getAllStock(URLparams); },
    async getAllStock() {
      this.loading = true;
      try {
        const { data: { results, total } } = await stockAPI.getAllStock(this.$route.query);
        this.products = results;
        this.totalProducts = total;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    getRemainingValue({ totalRemainingBuyValue, totalRemainingSellValue }) {
      this.totalRemainingBuyValue = totalRemainingBuyValue;
      this.totalRemainingSellValue = totalRemainingSellValue;
    },
  },
};
</script>
