<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <semen-table
      :id="$route.params.id"
      :title="$t('semensee.title')"
    />
    <v-btn
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5"
      depressed
      @click="() => openSemenForm(undefined, $route.params.id)"
    >
      {{ $t('semensee.add') }}
      <v-icon right>
        mdi-plus
      </v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import SemenTable from '@/components/semenCollection/SemenTable';

export default {
  components: { SemenTable },
  data: () => ({
    createDialog: false,
  }),
  methods: {
    openSemenForm(id, horse) {
      this.$router.push({ path: `/semen/create/${id}?horse=${horse}` });
    },
  },
};
</script>
