<template>
  <div v-if="$auth.isAuthenticated">
    <v-autocomplete
      v-model="select"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      prepend-inner-icon="mdi-magnify"
      flat
      dense
      hide-no-data
      hide-details
      hide-selected
      item-text="description"
      item-value="_id"
      :label="$t('common.actions.search')"
      autocomplete="off"
      solo-inverted
      return-object
      no-filter
    >
      <template #item="{ item }">
        <v-list-item @click="openPage(item.url)">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.description }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.company">
              {{ `${ item.first_name ? item.first_name : '' } ${ item.last_name ? item.last_name : '' }` }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.company">
              {{ $t('vat') }} {{ item.tva || '-' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import { ownerName } from '@/Helpers';
import searchAPI from '@/services/SearchAPI.js';

export default {
  data() {
    return {
      loading: false,
      entries: [],
      search: null,
      select: null,
    };
  },
  computed: {
    items() {
      return this.entries.map((entry) => {
        if (entry.type === 'Hengst' || entry.type === 'Merrie') {
          return {
            ...entry,
            description: entry.name,
            icon: 'mdi-horseshoe',
            url: `/horse/${entry._id}`,
          };
        }
        return {
          ...entry,
          description: ownerName(entry),
          icon: entry.type === 'bedrijf' ? 'mdi-factory' : 'mdi-account',
          url: `/customer/${entry._id}`,
        };
      });
    },
  },
  watch: {
    search(val) {
      if (val && val !== this.select) this.querySelections(val);
    },
  },
  methods: {
    async querySelections(v) {
      this.loading = true;
      try {
        const { data } = await searchAPI.getSearch(v, { limit: 10 });
        this.entries = data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    openPage(url) {
      this.$router.push(url);
    },
  },
};
</script>
