<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <v-card
      class="ma-5"
      outlined
    >
      <v-tabs
        v-model="type"
        center-active
      >
        <v-tab
          key="particulier"
          href="#particulier"
        >
          {{ $t('customerindex.part') }}
        </v-tab>
        <v-tab
          key="bedrijf"
          href="#bedrijf"
        >
          {{ $t('customerindex.corp') }}
        </v-tab>
        <v-tab-item value="particulier">
          <customer-table :headers="contactHeaders" />
        </v-tab-item>
        <v-tab-item value="bedrijf">
          <customer-table :headers="companyHeaders" />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-layout>
</template>

<script>
import customerTable from '@/components/customer/customerTable';
import i18n from '@/translations/i18n';

export default {
  components: { customerTable },
  data: () => ({
    contactHeaders: [
      { text: i18n.t('customerindex.company'), value: 'company', selected: false },
      { text: i18n.t('customerindex.first'), value: 'first_name', selected: true },
      { text: i18n.t('customerindex.last'), value: 'last_name', selected: true },
      { text: i18n.t('customerindex.made'), value: 'createdAt', align: 'end', sortable: false, selected: true },
    ],
    companyHeaders: [
      { text: i18n.t('customerindex.company'), value: 'company', selected: true },
      { text: i18n.t('customerindex.nr'), value: 'tva', selected: true },
      { text: i18n.t('customerindex.first'), value: 'first_name', selected: false },
      { text: i18n.t('customerindex.last'), value: 'last_name', selected: false },
      { text: i18n.t('customerindex.made'), value: 'createdAt', align: 'end', sortable: false, selected: true },
    ],
  }),
  computed: {
    type: {
      set(type) {
        if (this.$route.query.type !== type) {
          this.$router.replace({ query: {
            ...this.$route.query,
            first_name: undefined,
            last_name: undefined,
            company: undefined,
            type,
          } });
        }
      },
      get() {
        return this.$route.query.type;
      },
    },
  },
  methods: {
    openCustomerPage(id) {
      document.body.style.cursor = 'default';
      this.$router.push(`/customer/${id}`);
    },
  },
};
</script>
