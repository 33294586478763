<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card flat>
          <v-card-title>{{ $t('horseform.title') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="horse.name"
                  :rules="required"
                  :label="$t('horseform.name')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="horse.type"
                  :rules="required"
                  :items="horseType"
                  :label="$t('horseform.sex')"
                  :disabled="horse.death"
                  :loading="loading"
                  value="Merrie"
                  :menu-props="{ offsetY: true }"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <date-picker
                  :date="horse.date_of_birth"
                  :label="$t('horseform.birth')"
                  :close-on-input="true"
                  :disabled="horse.death"
                  :required="false"
                  @select-date="horse.date_of_birth = $event"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <search-customer
                  :outlined="false"
                  :customer="horse.owner"
                  :disabled="horse.death"
                  :label="$t('horseform.owner')"
                  @emit-customer="updateCustomer"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="horse.ueln"
                  :counter="15"
                  :rules="rules"
                  :label="$t('horseform.ueln')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="horse.microchip"
                  :rules="rules"
                  :counter="15"
                  max-length="15"
                  :label="$t('horseform.chip')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>
              <v-col
                v-if="horse.stud_horse"
                cols="12"
                md="4"
              >
                <search-customer
                  :outlined="false"
                  :customer="horse.stallionOwner"
                  :disabled="horse.death"
                  :label="$t('horseform.stallionowner')"
                  @emit-customer="updateStallionOwner"
                />
              </v-col>
              <v-slide-x-transition>
                <v-col
                  v-if="horse.stud_horse"
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="horse.stud_fee"
                    :label="$t('horseform.fee')"
                    :disabled="horse.death"
                    :loading="loading"
                  />
                </v-col>
              </v-slide-x-transition>
              <v-slide-x-transition>
                <v-col
                  v-if="horse.death"
                  cols="12"
                  md="4"
                >
                  <date-picker
                    :date="horse.date_of_death"
                    :label="$t('horseform.death')"
                    :close-on-input="true"
                    :required="horse.death"
                    @select-date="horse.date_of_death = $event"
                  />
                </v-col>
              </v-slide-x-transition>
              <v-slide-x-transition>
                <v-col
                  v-if="horse.surrogate"
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="horse.surrogate_uid"
                    :label="$t('horseform.sorrugateuid')"
                    :disabled="horse.death"
                  />
                </v-col>
              </v-slide-x-transition>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card flat>
          <v-card-title>{{ $t('horseform.title2') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-if="!horse._id"
                  v-model="location.stable"
                  :items="stables"
                  :label="$t('horseform.stable')"
                  :loading="loading"
                  :menu-props="{ offsetY: true }"
                  clearable
                  @input="selectStable"
                  @click:clear="clearStable"
                />
                <v-text-field
                  v-else
                  v-model="horse.location.stable"
                  :label="$t('horseform.stable')"
                  readonly
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-row v-if="!horse._id">
                  <v-col
                    v-if="showPrefix"
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="locationPrefix"
                      :items="prefixes"
                      :label="$t('horseform.prefix')"
                      :loading="loading"
                      :menu-props="{ offsetY: true }"
                      clearable
                      @click:clear="locationPrefix = ''"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    :md="showPrefix ? 8 : 12"
                  >
                    <v-select
                      v-model="location._id"
                      :items="filteredLocationsOnPrefix"
                      item-value="_id"
                      item-text="name"
                      item-disabled="disabled"
                      :suffix="location.disabled ? '(Volzet)' : ''"
                      :label="$t('horseform.location')"
                      :disabled="!location.stable"
                      :menu-props="{ offsetY: true }"
                      return-object
                      clearable
                      @input="selectLocation"
                      @click:clear="clearLocation"
                    />
                  </v-col>
                </v-row>
                <v-text-field
                  v-else
                  :label="$t('horseform.location')"
                  :value="horse.location.prefix ? `${horse.location.prefix}-${horse.location.name}` : horse.location.name"
                  readonly
                />
              </v-col>
              <v-col
                v-if="!horse._id"
                cols="12"
                md="2"
                align-self="center"
                class="d-flex align-center"
              >
                <v-checkbox
                  v-model="billable"
                  :label="$t('horseform.billable')"
                />
                <v-tooltip
                  bottom
                  :max-width="400"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span>{{ $t('horseform.addspan') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card flat>
          <v-card-title>{{ $t('horseform.title3') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="horse.breed"
                  :rules="required"
                  :items="horseBreeds"
                  :label="$t('horseform.breed')"
                  :disabled="horse.death"
                  :loading="loading"
                  :menu-props="{ offsetY: true }"
                  clearable
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="horse.studbook"
                  :items="horseStudbook"
                  :label="$t('horseform.studbook')"
                  :disabled="horse.death"
                  :loading="loading"
                  :menu-props="{ offsetY: true }"
                  clearable
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="horse.coat_color"
                  :items="horseCoatColor"
                  :label="$t('horseform.coat')"
                  :disabled="horse.death"
                  :loading="loading"
                  :menu-props="{ offsetY: true }"
                  clearable
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="horse.father"
                  :label="$t('horseform.father')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="horse.mother"
                  :label="$t('horseform.mother')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="horse.grandfather"
                  :label="$t('horseform.grandf')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card flat>
          <v-card-title>{{ $t('horseform.title4') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-if="horse.type === 'Hengst'"
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="horse.stud_horse"
                  inset
                  :label="$t('horseform.stud')"
                  :disabled="horse.death"
                />
              </v-col>
              <v-col
                v-if="horse.type === 'Hengst'"
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="horse.WFFS_free"
                  inset
                  :label="$t('horseform.wffs')"
                  :disabled="horse.death"
                />
              </v-col>
              <v-col
                v-if="horse.type === 'Merrie'"
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="horse.surrogate"
                  inset
                  :label="$t('horseform.surrogate')"
                  :disabled="horse.death"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="horse.keep_for_food_chain"
                  inset
                  :label="$t('horseform.chain')"
                  :disabled="horse.death"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="horse.death"
                  color="red"
                  inset
                  :label="$t('horseform.dead')"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            <v-row justify="end">
              <v-btn
                depressed
                class="mr-4"
                color="primary"
                @click="$router.back()"
              >
                {{ $t('horseform.back') }}
              </v-btn>
              <v-btn
                v-if="!horse._id"
                :disabled="!valid"
                color="success"
                class="mr-4"
                depressed
                @click="createHorse()"
              >
                {{ $t('horseform.save') }}
              </v-btn>
              <v-btn
                v-if="horse._id && horse.surrogate && horse.name && horse.date_of_birth && horse.surrogate_uid && horse.microchip"
                color="primary"
                depressed
                class="mr-4"
                @click="downloadResearchSheet()"
              >
                {{ $t('horseform.save') }}
              </v-btn>
              <v-btn
                v-if="horse._id"
                :disabled="!valid"
                color="success"
                depressed
                class="mr-4"
                @click="updateHorse()"
              >
                {{ horse.type }} {{ $t('horseform.edit') }}
              </v-btn>
              <v-btn
                v-if="horse._id"
                color="warning"
                depressed
                @click="deleteDialog = true"
              >
                {{ horse.type }} {{ $t('horseform.delete') }}
              </v-btn>
              <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="350"
              >
                <v-card>
                  <v-card-title class="headline">
                    {{ $t('horseform.condelete') }}
                  </v-card-title>
                  <v-card-text>
                    {{ $t('horseform.question1') }} {{ horse.name }} {{ $t('horseform.question2') }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="success"
                      depressed
                      @click="deleteDialog = false"
                    >
                      {{ $t('horseform.cancel') }}
                    </v-btn>
                    <v-btn
                      color="error"
                      depressed
                      @click="deleteHorse()"
                    >
                      {{ $t('horseform.delete2') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <snackbar
      :snackbar="snackbar"
      :text="$t('horseform.success')"
      color="success"
      @timeout="snackbar = $event"
    />
  </v-form>
</template>
<script>
import SearchCustomer from '@/components/search/SearchCustomer';
import DatePicker from '@/components/DateSelector/DatePicker';
import { formatDate, required } from '@/Helpers';
import { configAPI } from '@/services';
import horseAPI from '@/services/HorseAPI.js';
import Snackbar from '@/components/common/Snackbar';
import locationAPI from '@/services/LocationAPI';
import { mapActions } from 'vuex';
import { downloadResearchSheetPDF } from '@/Helpers/research-sheet.helper';
import { getLocationPrefixes } from '@/Helpers/location.helper';

export default {
  components: { SearchCustomer, DatePicker, Snackbar },
  props: {
    horse: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
    stud: { type: String, default: '' },
    type: { type: String, default: '' },
    surr: { type: String, default: '' },
  },
  data: () => ({
    locationsLoading: false,
    deleteDialog: false,
    snackbar: false,
    valid: false,
    horseType: ['Hengst', 'Merrie'],
    search: null,
    owner: '',
    horseStudbook: [],
    horseCoatColor: [],
    horseBreeds: [],
    billable: true,
    lodgings: [],
    rules: [(v) => {
      if (v) {
        return v.length <= 15 || 'Max 15 characters';
      }
      return true;
    }],
    stables: [],
    locations: [],
    prefixes: [],
    location: {},
    locationPrefix: '',
  }),
  computed: {
    required,
    filteredLocationsOnPrefix() {
      return this.locationPrefix
        ? this.locations.filter((loc) => loc.prefix?.toLowerCase() === this.locationPrefix.toLowerCase())
        : this.locations;
    },
    showPrefix() {
      return this.locations.some((loc) => loc.prefix);
    },
  },
  watch: {
    'horse.location': {
      deep: true,
    },
  },
  mounted() {
    this.getHorseInfo();
    if (this.type) {
      this.horse.type = this.type;
    }
    if (this.stud) {
      this.horse.stud_horse = this.stud;
    }
    if (this.surr) {
      this.horse.surrogate = this.surr;
    }
  },
  methods: {
    ...mapActions({
      resetError: 'errorMessage/resetError',
      setError: 'errorMessage/setError',
    }),
    formatDate,
    updateCustomer(searchResult) {
      this.horse.owner = searchResult ? searchResult._id : undefined;
    },
    updateStallionOwner(searchResult) {
      this.horse.stallionOwner = searchResult ? searchResult._id : undefined;
    },
    async createHorse() {
      try {
        if (!this.location || (!this.location.name || !this.location._id)) {
          this.horse.location = undefined;
        } else {
          this.lodgings.push({
            location: this.location._id,
            arrival: new Date(),
            billable: this.billable,
          });
          this.horse.location = this.location;
        }
        this.horse.lodgings = this.lodgings;
        const response = await horseAPI.postHorse(this.horse);

        this.$emit('update-horse', response.data);
        await this.resetError();
        await this.$router.back();
      } catch (err) {
        await this.setError(err.message);
      }
    },
    async updateHorse() {
      try {
        if (!this.horse.location || (!this.horse.location.name || !this.horse.location._id)) {
          this.horse.location = undefined;
        }
        const response = await horseAPI.putHorse(this.horse);
        this.$emit('update-horse', response.data);
        await this.resetError();
        await this.$router.back();
      } catch (err) {
        await this.setError(err.message);
      }
    },
    async deleteHorse() {
      try {
        await horseAPI.deleteHorse(this.horse._id);
        await this.resetError();
      } catch (err) {
        await this.setError(err.message);
      } finally {
        this.$router.back();
        this.dialog = false;
      }
    },
    async getHorseInfo() {
      try {
        const {
          data: {
            studbook,
            colors,
            stables,
            breeds,
          },
        } = await configAPI.getHorseTypes();
        this.horseStudbook = studbook;
        this.horseCoatColor = colors;
        this.horseBreeds = breeds;
        this.stables = stables;
        await this.resetError();
      } catch (err) {
        await this.setError(err.message);
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    downloadResearchSheet() {
      downloadResearchSheetPDF(this.horse.name.replace(/\s+/g, '-').toLowerCase(), [this.horse]);
    },
    async getStables() {
      try {
        const { data: { stables } } = await configAPI.getHorseTypes();
        this.stables = stables;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      }
    },
    selectStable(stable) {
      if (stable) {
        this.location = {
          stable,
        };
        this.getLocations(this.location);
      }
    },
    clearStable() {
      this.locations = [];
      this.location = {};
    },
    selectLocation(location) {
      if (location) {
        this.location = location;
      }
    },
    clearLocation() {
      this.location = {};
    },
    async getLocations({ stable }) {
      this.loadingLocations = true;
      try {
        const { data: { locations } } = await locationAPI.getLocations({ stable });
        this.locations = locations.map((loc) => ({ ...loc, disabled: loc.horses.length === loc.places }));
        this.prefixes = getLocationPrefixes(this.locations);
      } catch (err) {
        await this.setError(err.message);
      } finally {
        this.loadingLocations = false;
      }
    },
  },
};
</script>
<style scoped>
  #booked-wrapper{
    color: #7f7f7f;
  }
  #booked{
    font-size: 0.75rem;
    margin: 0 0 0 5px;
  }
</style>
