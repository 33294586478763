export const medication = {
  title: 'Stock overview',
  import: 'import medini',
  table: {
    headers: {
      name: 'Product name',
      type: 'Type',
      cnk: 'CNK',
      dose: 'Dosage',
      unit: 'Unit',
      tax: 'VAT',
      exp: 'Expiration date',
      waitTime: 'Waiting time (Days)',
      supplement: 'Supplement',
      totalPrice: 'Total sale price',
      remainingPrice: 'Remaining purchase price',
      remaining: 'Remaining',
    },
  },
  filters: {
    type: 'Filter on type',
    tax: 'Filter on VAT',
    remaining: 'Filter on remaining',
  },
};
