<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title>{{ $t("relocatedialog.title") }}</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <template v-for="batch in selected">
            <v-row :key="batch._id">
              <v-col cols="4">
                <v-select
                  v-model="batch.location.container"
                  :menu-props="{ offsetY: true }"
                  :label="$t('relocatedialog.container')"
                  :items="containers"
                  :rules="required"
                  item-text="name"
                  item-value="_id"
                  return-object
                  hide-details
                  outlined
                  required
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="batch.location.tube"
                  :menu-props="{ offsetY: true }"
                  :items="tubesAvailable(batch.location.container)"
                  :label="$t('relocatedialog.tube')"
                  hide-details
                  outlined
                  required
                  :rules="required"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="batch.location.position"
                  :items="['Boven', 'Onder']"
                  :menu-props="{ offsetY: true }"
                  :label="$t('relocatedialog.position')"
                  hide-details
                  outlined
                  required
                  :rules="required"
                />
              </v-col>
            </v-row>
          </template>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('close', !dialog)"
        >
          {{ $t("relocatedialog.cancel") }}
        </v-btn>
        <v-btn
          color="success"
          text
          :disabled="!selected.length || !valid"
          @click="() => relocate(selected)"
        >
          {{ $t("relocatedialog.move") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import nitrogenContainerAPI from '@/services/NitrogenContainerAPI';
import { tubesAvailable, required } from '@/Helpers';

export default {
  props: {
    dialog: { type: Boolean },
    selected: { type: Array, required: true },
    relocate: { type: Function, required: true },
  },
  data: () => ({
    valid: false,
    location: {},
    availableTubes: [],
    containers: [],
    editQueue: [],
  }),
  computed: { required },
  mounted() {
    this.getNitrogenContainers();
  },
  methods: {
    tubesAvailable,
    async getNitrogenContainers() {
      try {
        await this.$store.dispatch('errorMessage/resetError');
        const { data: { results } } = await nitrogenContainerAPI.getNitrogenContainers();
        this.containers = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
