<template>
  <v-row>
    <v-col cols="3">
      <v-select
        :value="transaction.transactionType"
        dense
        clearable
        outlined
        hide-details
        return-object
        :label="$t('transconfigselect.type')"
        :items="allTransactionTypes"
        :menu-props="{ offsetY: true }"
        @input="selectTransactionType"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        :value="transaction.transactionAction"
        dense
        clearable
        outlined
        hide-details
        return-object
        :label="$t('transconfigselect.action')"
        :items="possibleTransactionActions"
        :menu-props="{ offsetY: true }"
        @input="selectTransactionAction"
      />
    </v-col>
    <v-col
      v-if="deleteTransaction"
      cols="2"
    >
      <v-btn
        depressed
        color="error"
        @click="deleteTransaction"
      >
        <v-icon center>
          mdi-delete
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TransactionConfigSelector',
  props: {
    transaction: { type: Object, required: true },
    allTransactionActions: { type: Array, default: undefined },
    transactionActionsPerType: { type: Object, default: () => {} },
    allTransactionTypes: { type: Array, default: undefined },
    deleteTransaction: { type: Function, default: undefined },
  },
  computed: {
    possibleTransactionActions() {
      return this.findPossibleTransactionActions(this.transaction.transactionType);
    },
  },
  methods: {
    findPossibleTransactionActions(type) {
      return (type && this.transactionActionsPerType[type]) || this.allTransactionActions;
    },
    selectTransactionType(transactionType) {
      const possibleActions = this.findPossibleTransactionActions(transactionType);
      const transactionAction = this.transaction.transactionAction && possibleActions.includes(this.transaction.transactionAction)
        ? this.transaction.transactionAction
        : '';
      this.updateTransaction({ ...this.transaction, transactionType, transactionAction });
    },
    selectTransactionAction(transactionAction) {
      this.updateTransaction({ ...this.transaction, transactionAction });
    },
    updateTransaction(transaction) {
      this.$emit('update-transaction', transaction);
    },
  },
};
</script>

<style scoped>
</style>
