export const reports = {
  insemination: {
    title: 'Insemination evidence',
    client: 'Client',
    mare: 'Mare',
    name: 'Name:',
    ueln: 'UELN:',
    chip: 'Microchip:',
    stud: 'Stud',
    dateInsemination: 'Date insemination:',
    dateControl: 'Date control:',
    datePregnancy: 'Date planned birth:',
    title2: 'Add days to control date',
    amount: 'Amount',
    pregnancyStatement: 'Pregnancy statement',
    undersigned: 'The undersigned declares, having controlled the above mentioned mare on gestation by means of ultrasound examination, that she is / is not pregnant on',
    date: 'Date',
    vet: 'Veterinarian',
    selectUndersigned: 'Select undersigned',
    dateExecution: 'Date of execution',
    addStatement: 'Add pregnancy statement',
  },
  yearlyReports: {
    spermSentReceived: {
      stallion: 'Stallions',
      fresh: 'FRESH semen',
      frozen: 'FROZEN semen',
      breed: 'Breed',
      amountOfStallions: 'Amount of stallions',
      freshIn: 'Fresh IN',
      freshOut: 'Fresh OUT',
      frozenIn: 'Frozen IN',
      frozenOut: 'Frozen OUT',
    },
    spermGain: {
    },
  },
};
