export * from './dates';
export * from './names';
export * from './rules.helper';
export * from './notification.helper';

export const backgroundColor = (item, selectedList, inactive) => {
  if (selectedList.includes(item)) {
    return '#efefef';
  }
  if (inactive) {
    return '#f9f9f9';
  }
  return '';
};

export const strawColor = (color) => {
  switch (color) {
  case 'Clear':
    return 'grey--text text--lighten-4';
  case 'Gray':
    return 'grey--text text--lighten-2';
  case 'Pastel gray':
    return 'blue-grey--text text--lighten-3';
  case 'Yellow':
    return 'yellow--text';
  case 'Geel':
    return 'yellow--text';
  case 'Light yellow':
    return 'yellow--text text--lighten-3';
  case 'Pastel yellow':
    return 'amber--text text--lighten-2';
  case 'Putty':
    return 'lime--text text--darken-4';
  case 'Orange':
    return 'orange--text text--darken-1';
  case 'Oranje':
    return 'orange--text text--darken-1';
  case 'Pastel orange':
    return 'orange--text text--lighten-2';
  case 'Salmon':
    return 'deep-orange--text text--lighten-3';
  case 'Pink':
    return 'pink--text';
  case 'Red':
    return 'red--text';
  case 'Rood':
    return 'red--text';
  case 'Blue':
    return 'blue--text';
  case 'Blauw':
    return 'blue--text';
  case 'Pastel blue':
    return 'cyan--text text--lighten-3';
  case 'Royal blue':
    return 'blue--text text--darken-4';
  case 'Purple':
    return 'purple--text';
  case 'Violet':
    return 'purple--text';
  case 'Pastel purple':
    return 'deep-purple--text text--lighten-3';
  case 'Pastel pistachio':
    return 'light-green--text text--lighten-3';
  case 'Green':
    return 'green--text';
  case 'Groen':
    return 'green--text';
  case 'Pastel green':
    return 'green--text text-lighten-3';
  case 'Indigo':
    return 'indigo--text';
  default:
    return '';
  }
};

export const capitalize = (str) => {
  if (!str) return '-';
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

// eslint-disable-next-line no-extend-native
Array.prototype.groupBy = function (key) {
  return this.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const copyWithoutLayout = () => {
  document.addEventListener('copy', (e) => {
    const text_only = document.getSelection().toString();
    const clipdata = e.clipboardData || window.clipboardData;
    clipdata.setData('text/plain', text_only);
    clipdata.setData('text/html', text_only);
    e.preventDefault();
  });
};

export const tubesAvailable = (container) => {
  if (container && Array.isArray(container) && container.length) {
    const available = [];
    for (let i = 1; i <= container[0].available_places; i += 1) {
      available.push({
        text: `koker ${i}`,
        value: i,
      });
    }
    return available;
  }
  if (container && container._id) {
    const available = [];
    for (let i = 1; i <= container.available_places; i += 1) {
      available.push({
        text: `koker ${i}`,
        value: i,
      });
    }
    return available;
  }
  return [];
};

export const print = () => window.print();

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + Number(days));
  return date;
};

export const objectToQueryString = (obj) => Object.keys(obj).filter((key) => obj[key])
  .map((key) => `${key}=${obj[key]}`)
  .join('&');

export const queryStringToArray = (str) => {
  if (!str) {
    return null;
  }
  return str.split(',');
};

export const isEmpty = (obj) => Object.keys(obj).length === 0;

export const compareRouteAndPush = (query, route, router) => {
  if (route.fullPath === router.resolve({ query }).route.fullPath) {
    return;
  }
  if (!route.query.sortBy && !route.query.sortDesc) {
    router.replace({ query });
  } else {
    router.push({ query });
  }
};

/* eslint-disable-next-line */
export const getProtocolName = ({ amount, name }) => amount > 1 ? `${amount} x ${name}` : name;

export const reportQueryFormatter = (item) => {
  let queryString = '';
  if (item.protocol.parameters.length) {
    item.protocol.parameters.forEach((param) => {
      queryString += `&${param.elementModel.toLowerCase()}=${param.element._id || param.element}`;
    });
  }
  return queryString;
};
