import { capitalize } from '@/Helpers';
import VCalendar from 'v-calendar';
import Vue from 'vue';
import VueFilterDateFormat from 'vue-filter-date-format';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import App from './App.vue';
import { Auth0Plugin } from './auth';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store/index';
import './registerSW';
import i18n from './translations/i18n';

Vue.prototype.$appName = 'Equibase';

Vue.config.productionTip = false;

Vue.use(Auth0Plugin, {
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: async (appState) => {
    await router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
});

Vue.filter('capitalize', capitalize);

// Config of vuefilterdate
Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
    'Friday', 'Saturday',
  ],
  dayOfWeekNamesShort: [
    'Su', 'Mo', 'Tu', 'We', 'Tr', 'Fr', 'Sa',
  ],
  monthNames: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ],
  monthNamesShort: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ],
});

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

Sentry.init({
  Vue,
  dsn: 'https://94edf4db1d534449bf3103376767dea3@o1092247.ingest.sentry.io/4504677576343552',
  environment: process.env.VUE_APP_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['equibase.vercel.app', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
