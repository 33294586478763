<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <horse-table
      :headers="headers"
      :filters="filters"
    />
    <v-btn
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5 no-print"
      depressed
      @click="openCreateHorsePage()"
    >
      {{ $route.name }} {{ $t('mare.add') }}
      <v-icon right>
        mdi-plus
      </v-icon>
    </v-btn>
  </v-layout>
</template>
<script>
import horseTable from '@/components/horse/Table';
import { locationAPI } from '@/services';
import { displayLocation } from '@/Helpers/location.helper';
import i18n from '@/translations/i18n';

export default {
  components: { horseTable },
  data: () => ({
    locations: [],
    headers: [
      { text: i18n.t('mare.name'), value: 'name', selected: true, groupable: false },
      { text: i18n.t('mare.type'), value: 'type', sortable: false, selected: false },
      { text: i18n.t('mare.nr'), value: 'surrogate_uid', selected: true, groupable: false },
      { text: i18n.t('mare.chip'), value: 'microchip', selected: false, groupable: false },
      { text: i18n.t('mare.location'), value: 'location', selected: true },
      { text: i18n.t('mare.owner'), value: 'owner', selected: true },
      { text: i18n.t('mare.surrogate'), align: 'end', value: 'surrogate', selected: true, groupable: false },
      { text: i18n.t('mare.made'), align: 'end', value: 'createdAt', selected: false, groupable: false },
      { text: i18n.t('mare.edit'), align: 'end', value: 'updatedAt', selected: false, groupable: false },
      { text: i18n.t('mare.birth'), align: 'end', value: 'date_of_birth', selected: false, groupable: false },
    ],
  }),
  computed: {
    filters() {
      return {
        owner: { vTag: 'searchCustomer' },
        location: {
          vTag: 'select',
          label: i18n.t('mare.filter'),
          items: this.locations,
          objectItems: {
            text: (item) => displayLocation(item),
            value: '_id',
          },
          options: {
            multiple: true,
          },
        },
        surrogate: { vTag: 'switch', label: i18n.t('mare.show') },
        aliveOnly: { vTag: 'switch', label: i18n.t('mare.hide') },
      };
    },
  },
  mounted() { this.getLocations(); },
  methods: {
    displayLocation,
    openCreateHorsePage() {
      this.$router.push({ path: '/horse/create' });
    },
    async getLocations() {
      try {
        const { data: { locations } } = await locationAPI.getLocations();
        this.locations = locations;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
