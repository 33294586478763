export const horseName = (horse) => {
  if (horse) {
    return horse.name || '-';
  }
  return '-';
};

export const studOrigin = (stud) => ((stud.father && stud.grandfather) ? `${stud.father} X ${stud.grandfather}` : `${stud.father || stud.grandfather || '-'}`);

export const embryoHorses = (embryo) => (embryo ? `${horseName(embryo.donor_mare)} x ${horseName(embryo.donor_stallion)}` : '-');

export const ownerName = (owner) => {
  if (!owner || (!owner.company && (!owner.first_name || !owner.last_name))) return '-';
  if (owner.type === 'particulier') {
    return `${owner.first_name} ${owner.last_name}`;
  }
  return `${owner.company}`;
};

export const accountName = (account) => {
  if (!account) return '-';
  if (!account.number || !account.name) {
    throw new Error('Invalid Account');
  }
  return `${account.number} - ${account.name}`;
};

export const embryoDescription = (element) => {
  if (!element) return '-';

  const code = element.code || 'geen embryo';
  if (!element.location) {
    return `onbekende container - onbekende koker - onbekende positie - ${code}`;
  }
  if (!element.location.container) {
    return `onbekende container - Koker ${element.location.tube || 'onbekend'} - ${element.location.position || 'onbekende positie'} - ${code}`;
  }
  return `${element.location.container.name || 'onbekende container'} - Koker ${element.location.tube || 'onbekend'} - ${element.location.position || 'onbekende positie'} - ${code}`;
};

export const semenDescription = (element, tag = false) => {
  if (!element) return '-';
  if (!element.location) return 'onbekende locatie';

  const { location } = element;
  const tube = location.tube ? `Koker ${location.tube}` : 'onbekende koker';
  const position = location.position || 'onbekende positie';
  const container = location.container ? location.container.name : 'onbekende container';

  return tag ? `${element.stallion.name || 'onbekende hengst'} - ${element.lotNumber || 'onbekend lot'}`
    : `${container} - ${tube} - ${position} - ${element.stallion.name || 'onbekende hengst'} - ${element.lotNumber || 'onbekend lot'}`;
};

export const productFormatAmount = (product) => {
  if (!product) {
    return '-';
  }
  if (product.product && product.product.outgoingUnit) {
    return `${product.amount} ${product.product.outgoingUnit}`;
  }
  return `${product.amount} x`;
};
