<template>
  <v-card
    flat
    :class="!background ? 'light-grey' : ''"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-card
        outlined
        class="ma-5"
      >
        <v-toolbar
          flat
          dense
          light
        >
          <v-toolbar-title>{{ $t('productform.title') }}</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-row class="ma-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="product.name"
              :rules="required"
              :label="$t('productform.name')"
              :loading="loading"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="product.type"
              :menu-props="{ offsetY: true }"
              :rules="required"
              :items="types"
              :label="$t('productform.type')"
              :loading="loading"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="product.tax"
              :menu-props="{ offsetY: true }"
              :rules="required"
              :items="taxes"
              :label="$t('productform.tax')"
              :loading="loading"
            />
          </v-col>
        </v-row>
        <v-row class="ma-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="product.CNK"
              :rules="required"
              type="text"
              :label="$t('productform.cnk')"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="product.dosage"
              :rules="required"
              type="number"
              min="0"
              :label="$t('productform.dosage')"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="product.outgoingUnit"
              :menu-props="{ offsetY: true }"
              :items="units"
              :label="$t('productform.unit')"
              :loading="loading"
            />
          </v-col>
        </v-row>
        <v-row class="ma-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="product.waitingTime"
              type="number"
              hint="dagen"
              :label="$t('productform.wait')"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="product.supplementAdministration"
              type="number"
              hint="prijs per manuele toediening"
              :label="$t('productform.supplement')"
              :loading="loading"
              prefix="€"
              placeholder="0.00"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="product.sellingPricePerUnit"
              type="number"
              hint="hoogste verkoopprijs"
              :label="$t('productform.price')"
              :loading="loading"
              prefix="€"
              placeholder="0.00"
              :rules="required"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <div class="mr-2">
      <v-row
        justify="end"
        dense
      >
        <v-btn
          depressed
          color="primary"
          class="mr-4"
          @click="$router.push(route)"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          {{ $t('productform.back') }}
        </v-btn>
        <v-btn
          v-if="!product._id"
          :disabled="!valid"
          color="success"
          class="mr-4"
          depressed
          @click="createProduct()"
        >
          <v-icon left>
            mdi-content-save-outline
          </v-icon>
          {{ $t('productform.save') }}
        </v-btn>
        <v-btn
          v-if="product._id"
          :disabled="!valid"
          color="success"
          class="mr-4"
          depressed
          @click="editProduct()"
        >
          <v-icon left>
            mdi-content-save-outline
          </v-icon>
          {{ $t('productform.edit') }}
        </v-btn>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { required } from '@/Helpers';
import { productsAPI, configAPI } from '@/services';

export default {
  props: ['product', 'loading', 'disabled', 'background', 'route'],
  data: () => ({
    valid: false,
    types: [],
    taxes: [],
    units: [],
  }),
  computed: { required },
  mounted() { this.getConfig(); },
  methods: {
    async productHandler(data) {
      this.$emit('update-product', data);
      await this.$store.dispatch('errorMessage/resetError');
      await this.$router.back();
    },
    async createProduct() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await productsAPI.postProduct(this.product);
        await this.productHandler(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async editProduct() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await productsAPI.putProduct(this.product);
        await this.productHandler(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getConfig() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data: { types, tax, units } } = await configAPI.getProductConfig();
        this.types = types;
        this.taxes = tax;
        this.units = units;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
