<template>
  <v-menu
    v-if="!hidden"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-btn
        depressed
        color="primary"
        class="ml-4 d-print-none"
        v-bind="attrs"
        :disabled="selected.length === 0"
        v-on="on"
      >
        <v-icon left>
          mdi-chevron-down
        </v-icon>
        {{ $t("buttonaction") }} {{ selected.length > 0 ? '(' + selected.length + ') ' : "" }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in actionMenu"
        :key="index"
        :disabled="disabled(item)"
        @click="item.action(item.type)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ButtonWithMenu',
  props: {
    actionMenu: { type: Array, required: true },
    selected: { type: Array, required: true },
    hidden: { type: Boolean },
  },
  computed: {
    checkForUniqueHorse() {
      return !(this.selected && this.selected.every((batch) => batch.stallion._id === this.selected[0].stallion._id));
    },
    checkForUniqueClient() {
      return !(this.selected && this.selected.every((batch) => batch.owner._id === this.selected[0].owner._id));
    },
  },
  methods: {
    disabled(item) {
      return item.uniqueClient ? this.checkForUniqueClient || this.checkForUniqueHorse : false;
    },
  },
};
</script>
