<template>
  <div>
    <v-card
      flat
      outlined
    >
      <v-toolbar
        flat
        dense
      >
        <v-toolbar-title>{{ $t('uterusform.title') }}</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-row class="mx-5 mt-5">
        <v-col>
          <span>{{ $t('uterusform.span') }}</span>
          <v-slider
            v-model="uterusData.rating"
            :tick-labels="oedeemLabels"
            step="1"
            min="0"
            max="4"
            ticks
          />
        </v-col>
      </v-row>
      <v-row class="mx-5">
        <v-col
          cols="6"
          md="3"
          class="pt-0"
        >
          <v-select
            v-model="content.moist"
            :label="$t('uterusform.moist')"
            :items="moistList"
            :menu-props="{ offsetY: true }"
            clearable
            @click:clear="clear"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
          class="pt-0"
        >
          <v-select
            v-model="content.type"
            :label="$t('uterusform.type')"
            :items="moistType"
            :menu-props="{ offsetY: true }"
            :disabled="!content.moist"
            clearable
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
          class="pt-0"
        >
          <v-layout
            column
            align-center
          >
            <v-switch
              v-model="pregnantStatus"
              inset
              :label="$t('uterusform.preg')"
              @change="status => pregnantAmount = status ? 'Eenling' : undefined"
            />
          </v-layout>
        </v-col>
        <v-col
          cols="6"
          md="3"
          class="pt-0"
        >
          <v-select
            v-model="pregnantAmount"
            :label="$t('uterusform.amount')"
            :items="pregnantOptions"
            :menu-props="{ offsetY: true }"
            :disabled="!pregnantStatus"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    pregnant: { type: String, default: undefined },
    pregnantOptions: { type: Array, required: true },
    uterusData: { type: Object, required: true, default: () => {} },
    moistList: { type: Array, required: true },
    moistType: { type: Array, required: true },
  },
  data: () => ({
    oedeemLabels: ['Geen', 'Beginnend', 'Graad 1', 'Graad 2', 'Graad 3'],
    pregnantAmount: undefined,
    pregnantStatus: false,
    content: {},
  }),
  watch: {
    content: {
      handler() {
        this.uterusData.content = this.content;
      },
      deep: true,
    },
    pregnant() {
      this.pregnantStatus = !!this.pregnant;
      this.pregnantAmount = this.pregnant;
    },
    pregnantAmount() {
      this.$emit('emit-pregnant', this.pregnantAmount);
    },
  },
  created() {
    if (this.uterusData.content) {
      this.content = this.uterusData.content;
    }
    this.pregnantStatus = !!this.pregnant;
    this.pregnantAmount = this.pregnant;
  },
  methods: {
    clear() {
      this.uterusData.content = {};
      this.content = {};
    },
  },
};
</script>
