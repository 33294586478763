<template>
  <v-card flat>
    <v-toolbar flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="$route.query.from || $route.query.to"
        text
        color="error"
        @click="setDateRange({})"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetchMods"
        @fetching="loading = $event"
        @fetchedResults="mods = $event"
        @fetchedTotal="totalMods = $event"
      />
      <columns
        :headers="headers"
        @emit-headers="filteredHeaders = $event"
      />
    </v-toolbar>
    <v-data-table
      :headers="filteredHeaders"
      :items="mods"
      :server-items-length="totalMods"
      :options.sync="options"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :loading="loading"
      :loading-text="$t('common.loading')"
      class="ma-5"
    >
      <template #no-data>
        {{ $t('nomods') }}
      </template>
      <template #item="props">
        <tr>
          <td v-if="showColumn('type')">
            {{ props.item.type }}
          </td>
          <td v-if="showColumn('product')">
            {{ props.item.product ? props.item.product.name : '-' }}
          </td>
          <td v-if="showColumn('batch')">
            {{ props.item.batch ? props.item.batch.lotNumber : '-' }}
          </td>
          <td v-if="($route.query.type === 'Aankoop') && showColumn('batch.supplier')">
            {{ props.item.batch && props.item.batch.supplier ? props.item.batch.supplier : '-' }}
          </td>
          <td v-if="$route.query.out && showColumn('client')">
            {{ ownerName(props.item.client) }}
          </td>
          <td v-if="($route.query.type === 'Toediening') || $route.query.out && showColumn('horse')">
            {{ props.item.horse ? props.item.horse.name : '-' }}
          </td>
          <td v-if="showColumn('amount')">
            {{ props.item.amount }}
          </td>
          <td
            v-if="showColumn('createdAt')"
            class="text-right"
          >
            {{ formatDate(props.item.createdAt) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { formatDate, ownerName, compareRouteAndPush } from '@/Helpers';
import { stockAPI } from '@/services';
import Filters from '@/components/filters/Filters';
import Columns from '@/components/filters/Columns';

export default {
  components: { Filters, Columns },
  props: {
    title: { type: String, required: true },
    headers: { type: Array, required: true },
    filters: { type: Object, required: true },
  },
  data: (data) => ({
    mods: [],
    totalMods: 0,
    filteredHeaders: [],
    sortBy: 'createdAt',
    sortDesc: true,
    dateMenu: false,
    loading: false,
    range: {},
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
  }),
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush({
          ...this.$route.query,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
        }, this.$route, this.$router);
      },
    },
  },
  methods: {
    ownerName,
    formatDate,
    compareRouteAndPush,
    showColumn(col) { return this.headers.find((header) => header.value === col).selected; },
    setDateRange({ end, start }) {
      this.compareRouteAndPush({
        ...this.$route.query,
        from: start,
        to: end,
      }, this.$route, this.$router);
    },
    fetchMods(URLparams) { return stockAPI.getStockMods(URLparams); },
  },
};
</script>
