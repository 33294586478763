import { auth } from '@/translations/english/auth';
import { cases } from '@/translations/english/cases';
import { common } from '@/translations/english/common';
import { config } from '@/translations/english/config';
import { customers } from '@/translations/english/customers';
import { dashboard } from '@/translations/english/dashboard';
import { fertility } from '@/translations/english/fertility';
import { horses } from '@/translations/english/horses';
import { medication } from '@/translations/english/medication';
import { reports } from '@/translations/english/reports';
import { englishToRefactor } from '@/translations/english/englishToRefactor';

export const english = {
  ...englishToRefactor,
  // One translation file per primary nav item + common
  auth,
  cases,
  common,
  config,
  customers,
  dashboard,
  fertility,
  horses,
  medication,
  reports,
};
