
import Vue from 'vue';
import { tenantAPI } from '@/services';

export default Vue.extend({
  components: {},
  async beforeMount() {
    if (!this.$store.state.tenantInfo.activeTenant) {
      try {
        const { data: tenant } = await tenantAPI.getUserTenant();
        await this.$store.dispatch('tenantInfo/setActiveTenant', { tenant });
      } catch (err) {
        // TODO
      }
    }
  },
  methods: {},
});
