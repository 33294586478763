<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <ICSITable
      :title="$t('fertility.icsi.title')"
      :headers="headers"
      :filters="filters"
    />
    <v-btn
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5"
      depressed
      @click="openCreateICSIPage"
    >
      {{ $route.name }} {{ $t('fertility.icsi.add') }}
      <v-icon right>
        mdi-plus
      </v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import ICSITable from '@/components/icsi/Table';
import { horseName, tubesAvailable } from '@/Helpers';
import i18n from '@/translations/i18n';
import { nitrogenContainerAPI } from '@/services';

export default {
  components: { ICSITable },
  data: () => ({
    nitrogenContainers: [],
    headers: [
      { selected: true, text: 'fertility.icsi.table.headers.code', value: 'code' },
      { selected: true, text: 'fertility.icsi.table.headers.donorMare', value: 'donor_mare' },
      { selected: true, text: 'fertility.icsi.table.headers.stallion', value: 'donor_stallion' },
      { selected: true, text: 'fertility.icsi.table.headers.amount', value: 'amount' },
      { align: 'end', selected: true, text: 'fertility.icsi.table.headers.updatedOn', value: 'updatedAt' },
      { align: 'end', selected: true, text: 'fertility.icsi.table.headers.createdOn', value: 'createdAt' },
    ],
  }),
  computed: {
    filters() {
      return {
        donor_mare: { vTag: 'searchHorse', horseType: 'Merrie' },
        donor_stallion: { vTag: 'searchHorse', horseType: 'Hengst' },
        container: {
          vTag: 'select',
          label: i18n.t('common.container'),
          items: this.nitrogenContainers,
          cols: 6,
          objectItems: {
            text: horseName,
            value: '_id',
          },
        },
        tube: {
          vTag: 'select',
          label: i18n.t('common.tube'),
          items: tubesAvailable(this.nitrogenContainers.filter((container) => container._id === this.$route.query.container)),
          cols: 6,
          objectItems: {
            text: 'text',
            value: (item) => item.value.toString(),
          },
          hideIf: {
            target: 'container',
            condition: undefined,
          },
        },
        onlyActive: {
          vTag: 'switch',
          label: i18n.t('fertility.icsi.filters.active'),
        },
      };
    },
  },
  created() {
    this.getNitrogenContainers();
  },
  methods: {
    openCreateICSIPage() {
      this.$router.push({ path: '/icsi/create' });
    },
    async getNitrogenContainers() {
      try {
        const { data: { results } } = await nitrogenContainerAPI.getNitrogenContainers();
        this.nitrogenContainers = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
