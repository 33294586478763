export const fertility = {
  icsi: {
    title: 'ICSI batches',
    add: 'Add batch',
    table: {
      headers: {
        code: 'Code',
        donorMare: 'Donor Mare',
        stallion: 'Stud',
        amount: "Amount of embryo's",
        updatedOn: 'Updated on',
        createdOn: 'Created on',
      },
    },
    filters: {
      active: "Only batches with active embryo's",
    },
  },
  semen: {
    add: 'Add semen batch',
    download: 'Download CSV',
    title: 'Horses with semen batches',
    table: {
      headers: {
        name: 'Name horse',
        initial: 'Initial # batches',
        current: 'Current # batches',
        mods: 'Modifications',
      },
    },
    filters: {
      exportType: 'Filter on export type from semen batches',
    },
  },
};
