<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <horse-table
      :headers="headers"
      :filters="filters"
      :sort-by="sortBy"
      :selectable="true"
      @update-download-data="downloadData=$event"
    />
    <v-row
      class="mb-5 mr-5"
      justify="end"
    >
      <v-btn
        style="width: fit-content; align-self: flex-end;"
        color="success"
        class="mr-5 mb-5"
        tag="a"
        :href="downloadData.dataUri"
        :download="downloadData.name"
      >
        Download CSV
        <v-icon right>
          mdi-file-download
        </v-icon>
      </v-btn>
      <v-btn
        style="width: fit-content; align-self: flex-end"
        color="primary"
        class="mr-5 mb-5"
        depressed
        @click="openCreateHorsePage()"
      >
        {{ $route.name }} {{ $t('common.add') }}
        <v-icon right>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-row>
  </v-layout>
</template>
<script>
import horseTable from '@/components/horse/Table';
import { displayLocation } from '@/Helpers/location.helper.ts';
import { horseAPI, locationAPI } from '@/services';
import { horseName } from '@/Helpers';
import i18n from '@/translations/i18n';

export default {
  components: { horseTable },
  data: () => ({
    createDialog: false,
    locations: [],
    headers: [
      { text: i18n.t('surrogate.name'), value: 'name', selected: true, groupable: false },
      { text: i18n.t('surrogate.type'), value: 'type', sortable: false, selected: false },
      { text: i18n.t('surrogate.nr'), value: 'surrogate_uid', selected: true, groupable: false },
      { text: i18n.t('surrogate.chip'), value: 'microchip', selected: false, groupable: false },
      { text: i18n.t('surrogate.location'), value: 'location', selected: true },
      { text: i18n.t('surrogate.owner'), value: 'owner', selected: true },
      { text: i18n.t('surrogate.surrogate'), align: 'end', value: 'surrogate', selected: true, groupable: false },
      { text: i18n.t('surrogate.embryo'), value: 'embryo', selected: true },
      { text: i18n.t('surrogate.planted'), value: 'embryoImplanted', selected: true },
      { text: i18n.t('surrogate.made'), align: 'end', value: 'createdAt', selected: false, groupable: false },
      { text: i18n.t('surrogate.edit'), align: 'end', value: 'updatedAt', selected: false, groupable: false },
      { text: i18n.t('surrogate.birth'), align: 'end', value: 'date_of_birth', selected: false, groupable: false },
    ],
    downloadData: { },
    sortBy: 'location',
  }),
  computed: {
    filters() {
      return {
        owner: { vTag: 'searchCustomer' },
        location: {
          vTag: 'select',
          label: i18n.t('surrogate.filter'),
          items: this.locations,
          cols: 6,
          objectItems: {
            text: (item) => displayLocation(item),
            value: '_id',
          },
          options: {
            multiple: true,
          },
        },
        microchip: {
          vTag: 'basicSearch',
          label: i18n.t('surrogate.chip'),
          searchFunction: this.searchMicrochip,
          format: this.horseName,
          getSpecific: this.getSpecificHorse,
          returnValue(item) { return item.microchip; },
          cols: 6,
        },
        surrogate_uid: {
          vTag: 'basicSearch',
          label: i18n.t('surrogate.nr'),
          searchFunction: this.searchSurrogateUID,
          format: this.horseName,
          getSpecific: this.getSpecificHorse,
          returnValue(item) { return item.surrogate_uid; },
        },
        aliveOnly: { vTag: 'switch', label: i18n.t('surrogate.hide') },
      };
    },
  },
  mounted() { this.getLocations(); },
  methods: {
    horseName,
    openCreateHorsePage() {
      this.$router.push({ path: '/horse/create' });
    },
    async searchMicrochip(v) {
      const { data } = await horseAPI.searchMicrochip(v, {
        surrogate: true,
        type: 'Merrie',
        limit: 10,
      });
      return data;
    },
    async searchSurrogateUID(v) {
      const { data } = await horseAPI.searchSurrogateUID(v, {
        surrogate: true,
        type: 'Merrie',
        limit: 10,
      });
      return data;
    },
    async getSpecificHorse(query) {
      const { data: { results } } = await horseAPI.getHorses({
        ...query,
        surrogate: true,
        type: 'Merrie',
        limit: 10,
      });
      return results && results.length ? results[0] : undefined;
    },
    async getLocations() {
      try {
        const { data: { locations } } = await locationAPI.getLocations();
        this.locations = locations;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
