export const cases = {
  add: 'Case toevoegen',
  title: 'Cases',
  table: {
    headers: {
      ref: 'Ref',
      horse: 'Paard',
      client: 'Klant',
      extra: 'Extra',
      activity: 'Hoofdactiviteit',
      amount: 'Aantal',
      status: 'status',
      updatedOn: 'Gewijzigd',
      createdAt: 'Gemaakt',
    },
  },
  search: 'Zoeken op referentie of paardnaam',
  filters: {
    status: 'Filter op status',
    activity: 'Filter op activiteit',
    hideSurrogates: 'Verberg draagmoeders',
    onlySurrogates: 'Enkel draagmoeders',
  },
};
