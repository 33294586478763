<template>
  <v-snackbar
    v-model="showError"
    top
    outlined
    text
    timeout="-1"
    color="error"
    @close="$store.dispatch('errorMessage/resetError')"
  >
    <v-icon
      color="error"
      class="mr-5"
    >
      mdi-alert
    </v-icon>
    {{ errorMessage.message || errorMessage }}

    <template #action="{ attrs }">
      <v-btn
        v-bind="attrs"
        icon
        @click="$store.dispatch('errorMessage/resetError')"
      >
        <v-icon
          color="error"
        >
          mdi-close-circle
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ErrorMessage',
  computed: {
    showError: {
      get() {
        return !!this.errorMessage;
      },
      set(value, code) {
        this.$store.dispatch('errorMessage/setError', { message: value, code });
      },
    },
    ...mapState({
      errorMessage: (state) => state.errorMessage.message,
    }),
  },
};
</script>
