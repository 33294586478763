<template>
  <v-dialog
    v-model="deleteDialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>{{ item }} {{ $t("deletedialog.title") }}</v-card-title>
      <v-card-text v-if="deleteQueue.name">
        <p>{{ $t("deletedialog.readyto") }}<b>{{ deleteQueue.name }}</b> {{ $t("deletedialog.todelete") }}</p>
      </v-card-text>
      <v-card-text v-else>
        <p>{{ $t("deletedialog.confirm") }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('close', false)"
        >
          {{ $t("deletedialog.cancel") }}
        </v-btn>
        <v-btn
          color="error"
          text
          @click="$emit('delete', deleteQueue)"
        >
          {{ $t("deletedialog.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteDialog',
  props: ['deleteDialog', 'deleteQueue', 'item'],
};
</script>
