<template>
  <v-layout
    column
    fill-height
  >
    <div :class="[isPrint ? 'print-only' : 'ma-5']">
      <v-data-table
        :items="spermPerStallion.data"
        :options.sync="options"
        :loading="spermPerStallion.loading"
        :headers="subheaders"
        :hide-default-footer="hideFooter"
        :disable-pagination="disablePagination"
        hide-default-header
      >
        <template #no-data>
          {{ $t('common.nodata') }}
        </template>
        <template #header>
          <thead class="v-data-table-header">
            <tr>
              <th
                v-for="head in headers"
                :key="head.value"
                :colspan="head.colspan"
              >
                {{ head.text }}
              </th>
            </tr>
            <tr>
              <th
                v-for="subheader in subheaders"
                :key="subheader.value"
              >
                {{ subheader.text }}
              </th>
            </tr>
          </thead>
        </template>
        <template #item="props">
          <tr>
            <td>{{ (props.item.studbook && props.item.studbook) || $t('reports.yearlyReports.spermGain.noBook') }}</td>
            <td>{{ (props.item.horsesCount && props.item.horsesCount) || 0 }}</td>
            <td>
              <div class="d-flex">
                <div class="mr-1">
                  {{ props.item.gainedSemenFresh }}
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex">
                <div class="mr-1">
                  {{ props.item.usedSemenFresh }}
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex">
                <div class="mr-1">
                  {{ props.item.sentSemenFresh }}
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex">
                <div class="mr-1">
                  {{ getRemainingDoses(props.item.gainedSemenFresh, props.item.usedSemenFresh, props.item.sentSemenFresh) }}
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex">
                <div class="mr-1">
                  {{ props.item.gainedSemenFrozen }}
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex">
                <div class="mr-1">
                  {{ props.item.usedSemenFrozen }}
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex">
                <div class="mr-1">
                  {{ props.item.sentSemenFrozen }}
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-layout>
</template>
<script>
import i18n from '@/translations/i18n';

export default {
  props: {
    stallions: { type: Array, default: undefined },
    isPrint: { type: Boolean, default: true },
    disablePagination: { type: Boolean, default: true },
    hideFooter: { type: Boolean, default: true },
  },
  data: (data) => ({
    options: {
      page: 1,
      itemsPerPage: data.disablePagination ? -1 : 10,
    },
    spermPerStallion: data.$store.state.yearlyReport.semenByStallion,
    headers: [
      { value: 'indentiteit', text: i18n.t('reports.yearlyReports.spermGain.id'), colspan: 2 },
      {
        value: 'gegevens-vers',
        text: i18n.t('reports.yearlyReports.spermGain.fresh'),
        colspan: 4,
      },
      {
        value: 'gegevens-diepvries',
        text: i18n.t('reports.yearlyReports.spermGain.frozen'),
        colspan: 3,
      },
    ],
    subheaders: [
      {
        value: 'stamboekNaam',
        text: i18n.t('reports.yearlyReports.spermGain.bookName'),
      },
      { value: 'aantal', text: i18n.t('reports.yearlyReports.spermGain.amountOfStallions') },
      {
        value: 'vers-gewonnen',
        text: i18n.t('reports.yearlyReports.spermGain.freshWon'),
      },
      {
        value: 'vers-gebruikt',
        text: i18n.t('reports.yearlyReports.spermGain.freshUsed'),
      },
      {
        value: 'vers-verzonden',
        text: i18n.t('reports.yearlyReports.spermGain.freshSent'),
      },
      {
        value: 'vers-niet gebruikt',
        text: i18n.t('reports.yearlyReports.spermGain.freshNotUsed'),
      },
      {
        value: 'diepvries-gewonnen',
        text: i18n.t('reports.yearlyReports.spermGain.frozenWon'),
      },
      {
        value: 'diepvries-gebruikt',
        text: i18n.t('reports.yearlyReports.spermGain.frozenUsed'),
      },
      {
        value: 'diepvries-verzonden',
        text: i18n.t('reports.yearlyReports.spermGain.frozenSent'),
      },
    ],
  }),
  computed: {
    year() {
      return this.$store.state.yearlyReport.year;
    },
  },
  watch: {
    year: {
      immediate: true,
      handler(newYear) {
        if (newYear) {
          this.fetchSemenByStallion();
        }
      },
    },
    'spermPerStallion.data': {
      immediate: true,
      handler(newData) {
        let csvData = 'data:text/csv;charset=utf-8,Indentiteit van de hengsten,,VERS SPERMA,,,,DIEPVRIES SPERMA\n';
        csvData += 'Stamboeknaam,Aantal hengsten,';
        csvData += 'Aantal gewonnen dosissen vers,Aantal dosissen gebruikt op centrum,';
        csvData += 'Aantal dosissen verzonden,Aantal dosissen niet gebruikt,';
        csvData += 'Aantal rietjes gewonnen,Aantal rietjes gebruikt op centrum,Aantal rietjes verzonden\n';

        if (newData && newData.length) {
          // TODO change niet gebruikt naar data property van API ( ook in HTML )
          newData.forEach((dataLine) => {
            csvData += `${dataLine.studbook || 'Geen stamboek'},`;
            csvData += `${dataLine.horsesCount},`;
            csvData += `${dataLine.gainedSemenFresh},${dataLine.usedSemenFresh},`;
            csvData += `${dataLine.sentSemenFresh},${this.getRemainingDoses(dataLine.gainedSemenFresh, dataLine.usedSemenFresh, dataLine.sentSemenFresh)},`;
            csvData += `${dataLine.gainedSemenFrozen},${dataLine.usedSemenFrozen},${dataLine.sentSemenFrozen}`;
            csvData += '\n';
          });
        }
        this.$emit('update-download-data', { dataUri: encodeURI(csvData), name: 'winnen-sperma-per-hengst.csv' });
      },
    },
  },
  methods: {
    async fetchSemenByStallion() {
      if (!this.spermPerStallion.data.length || this.spermPerStallion.year !== this.year) {
        this.$store.dispatch('yearlyReport/getSemenByStallion');
      }
    },
    getRemainingDoses(gain, used, sent) {
      const remaining = gain - used - sent;

      if (remaining < 0) {
        return 0;
      }

      return remaining;
    },
  },
};
</script>
